import Vue from 'vue'
import { EventBus, Events } from '../lib/Eventbus'

export const state = () => {
  return {
    airports: []
  }
}

export const getters = {
  airportCode: (state) => (code) => {
    const airport = state.airports.find(
      (a) => a.iata_code === code || a.icao_code === code
    )
    if (airport) {
      code = airport.iata_code
    }

    return code
  }
}

export const mutations = {
  setAirports(state, airports) {
    Vue.set(state, 'airports', airports)
  }
}

export const actions = {
  fetchAirports({ commit }) {
    return fetch(process.env.API_URL + '/api/airports')
      .then((res) => res.json())
      .then((res) => {
        commit('setAirports', res)
        EventBus.$emit(Events.data.airportsLoaded, res)
      })
      .catch(() => {})
  }
}
