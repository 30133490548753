const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['boostrap'] = require('../middleware/boostrap.js')
middleware['boostrap'] = middleware['boostrap'].default || middleware['boostrap']

middleware['checkPrivacyPolicy'] = require('../middleware/checkPrivacyPolicy.js')
middleware['checkPrivacyPolicy'] = middleware['checkPrivacyPolicy'].default || middleware['checkPrivacyPolicy']

middleware['checkSubscription'] = require('../middleware/checkSubscription.js')
middleware['checkSubscription'] = middleware['checkSubscription'].default || middleware['checkSubscription']

middleware['checkTermsOfService'] = require('../middleware/checkTermsOfService.js')
middleware['checkTermsOfService'] = middleware['checkTermsOfService'].default || middleware['checkTermsOfService']

middleware['googleAnalytics'] = require('../middleware/googleAnalytics.js')
middleware['googleAnalytics'] = middleware['googleAnalytics'].default || middleware['googleAnalytics']

middleware['isReady'] = require('../middleware/isReady.js')
middleware['isReady'] = middleware['isReady'].default || middleware['isReady']

middleware['postSignup'] = require('../middleware/postSignup.js')
middleware['postSignup'] = middleware['postSignup'].default || middleware['postSignup']

export default middleware
