<template>
  <v-app>
    <HeaderLoggedIn v-if="$auth.isAuthenticated" />
    <HeaderLoggedOut v-else />
    <v-main>
      <v-container fluid fill-height style="padding: 0; margin: 0;">
        <nuxt />
        <CookieControl>
          <template v-slot:bar>
            <h3>Cookie Consent</h3>
            <p>
              Cookies are important to the proper functioning of the Airport
              Viewer website and service. We use cookies and similar methods to
              recognize visitors and remember their preferences, to improve your
              experience (e.g., to remember log-in details), to collect
              statistics about how the site is used, and, in certain cases, to
              deliver content tailored to your interests. Our Cookie Policy is
              part of our Privacy Policy; see our
              <a href="/privacy" style="color: white;">Privacy Policy</a> for
              information. By choosing to Accept Cookies you consent to the
              these these methods by us and third parties. The Manage Cookies
              Preferences button allows you to chose which types of cookies you
              accept. You may withdraw or modify your consent via the Manage
              Cookie Preferences button at any time.
            </p>
          </template>
        </CookieControl>
      </v-container>
    </v-main>
    <Footer v-if="showFooter" :absolute="isMapPage" />
  </v-app>
</template>

<script>
/* global dataLayer */
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { EventBus, Events } from '../lib/Eventbus'
import HeaderLoggedIn from '../components/HeaderLoggedIn'
import HeaderLoggedOut from '../components/HeaderLoggedOut'
import Footer from '../components/Footer'

function gtag() {
  dataLayer.push(arguments)
}

export default {
  middleware: ['isReady', 'boostrap', 'googleAnalytics'],
  components: { HeaderLoggedIn, HeaderLoggedOut, Footer },
  data() {
    return {
      drawer: false,
      title: 'Viewer',
      cookies: this.$cookies
    }
  },
  computed: {
    cookieConsent() {
      let cookieConsent

      if (this.$auth.isAuthenticated) {
        cookieConsent = {
          consent: this.$cookies.consent,
          enabledList: this.$cookies.enabledList.join()
        }
      }

      return cookieConsent
    },
    ...mapGetters({
      isPWA: 'settings/isPWA',
      iataCode: 'settings/iataCode',
      currentTime: 'socket.io/currentTime'
    }),
    isMapPage() {
      return !!this.$route.path.match(/\/airport\/\w{3,4}/)
    },
    showFooter() {
      let showFooter = true
      if (this.isMapPage) {
        // only display footer on map if md device or greater
        showFooter = this.$vuetify.breakpoint.mdAndUp
      }

      return showFooter
    }
  },
  watch: {
    async cookieConsent() {
      // If cookieConsent is different than what is currently in state, save update.
      if (this.$auth.isAuthenticated) {
        const storedConsent = await this.$store.dispatch(
          'user/getCookieConsent'
        )

        if (
          !storedConsent ||
          this.cookieConsent.consent !== storedConsent.consent ||
          this.cookieConsent.enabledList !== storedConsent.enabledList
        ) {
          this.$store
            .dispatch('user/saveCookieConsent', this.cookieConsent)
            .then((r) => {
              this.setCookieConsent(r)
            })
        }
      }
    }
  },
  mounted() {
    if (process.env.ENABLE_GOOGLE_ANALYTICS) {
      window.dataLayer = window.dataLayer || []

      gtag('js', new Date())

      gtag('config', process.env.GOOGLE_ANALYTICS_ID)
    }
  },
  created() {
    EventBus.$on(Events.navigation.setMenuTitle, (title) => {
      this.title = title
    })

    if (this.$auth.user) {
      this.initAuthUser(this.$auth.user)
    }
  },
  methods: {
    ...mapActions({
      initAuthUser: 'user/initAuthUser',
      logout: 'user/logout'
    }),
    ...mapMutations({
      setCookieConsent: 'user/setCookieConsent'
    }),
    onLogout() {
      this.logout()
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>
