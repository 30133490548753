<template>
  <v-card tile flat>
    <h2 class="pt-3 text-center">Forgot Password</h2>
    <v-card-text class="text--primary">
      <v-form ref="forgotForm" v-model="forgotForm">
        <v-container>
          <v-row justify="center">
            <v-col cols="10">
              <h3>Email</h3>
              <v-text-field
                v-model="email"
                type="email"
                :append-icon="emailIcon"
                :error-messages="emailErrorMessages"
                placeholder="name@example.com"
                filled
                required
                outlined
                dense
                data-cy="forgotpass-email"
                @keydown.enter="submitForgot"
                @focus="$v.email.$reset()"
                @blur="$v.email.$touch()"
              ></v-text-field>

              <v-alert
                v-if="message"
                :color="messageSuccess ? 'green' : 'red'"
                colored-border
                elevation="1"
                border="left"
              >
                {{ message }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="6" class="text-center">
              <v-btn
                class="text-capitalize"
                block
                depressed
                color="primary"
                :loading="loading"
                @click="submitForgot"
              >
                Reset Password
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="10" class="text-center">
              <p class="mt-2">
                If you use a social media account to log in to Airport Viewer,
                please go to your social media website to change your password.
              </p>
              <p class="mt-2"><a @click="backToLogin">Back to Login</a></p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <p class="caption">
                Don't have an account yet? <a @click="signup">Sign up now!</a>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
import { Utils } from '../../lib/Utils'

export default {
  name: 'ForgotPassword',
  data() {
    return {
      connection: 'Username-Password-Authentication',
      forgotForm: true,
      email: '',
      message: '',
      messageSuccess: false,
      loading: false
    }
  },
  computed: {
    emailErrorMessages() {
      if (!this.$v.email.$dirty) {
        return []
      }
      const errors = []
      if (!this.$v.email.required) {
        errors.push('Email is required.')
      }
      if (!this.$v.email.email) {
        errors.push('Email is not in a supported format.')
      }
      return errors
    },
    emailIcon() {
      return Utils.inputValidationIcon(this.$v.email)
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    backToLogin() {
      this.$emit('forgot', false)
    },
    signup() {
      this.$emit('signup', true)
    },
    submitForgot() {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$auth.forgotPassword(this.email).then((err, data) => {
          if (err) {
            this.setMessage(false, err.description)
          } else {
            this.setMessage(
              true,
              'An email will be sent to the address provided with a reset link.'
            )
          }
        })
      }
    },
    setMessage(success, message) {
      this.messageSuccess = success
      this.message = message
    }
  }
}
</script>
