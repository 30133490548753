import Vue from 'vue'
import { Auth0WebAuthPlugin } from '../lib/Auth0WebAuthPlugin'

const domain = process.env.AUTH_DOMAIN
const clientId = process.env.CLIENT_ID
const audience = process.env.AUDIENCE

Vue.use(Auth0WebAuthPlugin, {
  domain,
  clientId,
  audience
})
