<template>
  <v-app-bar color="primary" flat app>
    <v-toolbar-title class="white--text" style="font-variant: small-caps;">
      <nuxt-link
        v-if="!isHomePage && !$vuetify.breakpoint.xsOnly"
        to="/"
        class="mr-4"
      >
        <img src="/home.svg" alt="home" style="transform: translateY(-3px)" />
      </nuxt-link>

      <nuxt-link :to="`/airport/${$store.getters['settings/previousAirport']}`">
        <img src="/group.svg" alt="map" />
      </nuxt-link>

      <div
        v-if="!$vuetify.breakpoint.xsOnly"
        style="border-right: 1px white solid; display: inline; margin: 0 25px;"
      />
      <div v-if="!$vuetify.breakpoint.xsOnly" style="display: inline;">
        <a href="https://www.mosaicatm.com/" target="_blank">
          <img src="/mosaic-atm-white.png" height="25px;" alt="logo" />
        </a>
      </div>
    </v-toolbar-title>
    <v-spacer />
    <div>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <div
            class="mr-10 white--text"
            style="text-transform: uppercase; font-size: 14px"
            v-on="on"
          >
            My Account
            <v-icon right x-small class="white--text">
              fas fa-chevron-down
            </v-icon>
          </div>
        </template>
        <v-list width="200">
          <v-list-item to="/settings">
            <v-list-item-action>
              <v-icon>
                fas fa-user
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Settings
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>
                fas fa-sign-out-alt
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>
<script>
export default {
  computed: {
    isHomePage() {
      return this.$route.name === 'index'
    }
  },
  methods: {
    logout() {
      this.$auth.logout()
    }
  }
}
</script>
