<template>
  <v-form>
    <v-tooltip
      v-model="showRules"
      :disabled="!tooltip"
      color="grey darken-4"
      top
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="password"
          type="password"
          color="secondary"
          :append-icon="passwordIcon"
          :error-messages="passwordErrorMessages"
          required
          filled
          outlined
          :dense="dense"
          @keydown.enter="submitSignup"
          v-on="on"
          @focus="
            $v.password.$reset()
            showRules = tooltip && true
          "
          @blur="
            $v.password.$touch()
            showRules = false
          "
        ></v-text-field>
      </template>
      <span>Password must contain:</span>
      <ul style="list-style: none">
        <li :class="$v.password.passwordLength ? successStyle : failStyle">
          between 8 and 50 characters
        </li>
        <li :class="$v.password.passwordUppercase ? successStyle : failStyle">
          at least one uppercase character
        </li>
        <li :class="$v.password.passwordLowercase ? successStyle : failStyle">
          at least one lowercase character
        </li>
        <li :class="$v.password.passwordNumber ? successStyle : failStyle">
          at least one number
        </li>
        <li :class="$v.password.passwordSymbol ? successStyle : failStyle">
          at least one special character (!@#$%^&*)
        </li>
      </ul>
    </v-tooltip>
  </v-form>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { Utils } from '../lib/Utils'

const passwordLength = (value) => {
  return /^(.{8,50})$/g.test(value)
}
const passwordUppercase = (value) => {
  return /(?=.*[A-Z]).+/g.test(value)
}
const passwordLowercase = (value) => {
  return /(?=.*[a-z]).+/g.test(value)
}
const passwordNumber = (value) => {
  return /(?=.*[0-9]).+/g.test(value)
}
const passwordSymbol = (value) => {
  return /(?=.*[(!@#$%^&*)]).+/g.test(value)
}

const matches = (value, matchTo) => {
  if (matchTo) {
    return value === matchTo
  }
  return true
}

export default {
  props: {
    // value to bind with v-model
    value: {
      type: String,
      required: true
    },
    // whether to show the tooltip
    tooltip: {
      type: Boolean,
      required: false,
      default: true
    },
    // password to match to
    match: {
      type: String,
      required: false,
      default: null
    },
    // apply dense prop
    dense: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      password: '',
      successStyle: 'green--text text--lighten-1',
      failStyle: 'red--text text--lighten-3',
      showRules: false
    }
  },
  computed: {
    ready() {
      return !this.$v.password.$invalid
    },
    passwordErrorMessages() {
      if (!this.$v.password.$dirty) {
        return []
      }
      const errors = []
      if (!this.$v.password.required) {
        errors.push('Password is required')
      }
      if (
        !this.$v.password.passwordLength ||
        !this.$v.password.passwordUppercase ||
        !this.$v.password.passwordLowercase ||
        !this.$v.password.passwordNumber ||
        !this.$v.password.passwordSymbol
      ) {
        errors.push('Password does not meet strength rules.')
      }

      if (!this.$v.password.passwordMatch) {
        errors.push('Passwords do not match.')
      }
      return errors
    },
    passwordIcon() {
      return Utils.inputValidationIcon(this.$v.password)
    }
  },
  watch: {
    password() {
      // update parent's v-model
      this.$emit('input', this.password)
    },
    ready(val) {
      // tell parent if validation passes
      this.$emit('ready', val)
    }
  },
  validations: {
    password: {
      required,
      passwordLength,
      passwordUppercase,
      passwordLowercase,
      passwordNumber,
      passwordSymbol,
      passwordMatch(value) {
        return matches(value, this.match)
      }
    }
  },
  created() {
    this.password = this.value
  },
  methods: {
    enter() {
      // tell parent when Enter key is hit
      this.$emit('enter', true)
    },
    touch() {
      this.$v.password.$touch()
    }
  }
}
</script>
