<template>
  <v-card tile flat>
    <h2 class="text-center dialog-title">Login</h2>
    <!--
    <div v-if="!isPWA" class="text-center caption">
      <a @click="continueAsGuest">
        Continue as guest
      </a>
    </div>
    -->
    <v-card-text class="text--primary">
      <v-form ref="loginForm" v-model="loginForm">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" sm="10">
              <h3>Email</h3>
              <v-text-field
                id="email"
                v-model="email"
                type="email"
                :append-icon="emailIcon"
                :error-messages="emailErrorMessages"
                placeholder="name@example.com"
                color="secondary"
                filled
                required
                outlined
                dense
                data-cy="login-email"
                @keydown.enter="submitLogin"
                @focus="$v.email.$reset()"
                @blur="$v.email.$touch()"
              >
              </v-text-field>
              <h3>Password</h3>
              <v-text-field
                id="password"
                v-model="password"
                type="password"
                :append-icon="passwordIcon"
                :error-messages="passwordErrorMessages"
                color="secondary"
                required
                filled
                outlined
                dense
                data-cy="login-password"
                @keydown.enter="submitLogin"
                @focus="$v.password.$reset()"
                @blur="$v.password.$touch()"
              ></v-text-field>

              <v-checkbox
                v-model="rememberMe"
                label="Keep me signed in"
                @change="setRememberMe"
              />

              <v-alert
                v-if="message"
                :color="messageSuccess ? 'green' : 'red'"
                colored-border
                elevation="1"
                border="left"
              >
                {{ message }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="10" sm="6" class="text-center">
              <v-btn
                class="text-capitalize"
                :loading="loading"
                block
                depressed
                color="primary"
                data-cy="login-submit"
                @click="submitLogin"
              >
                Login
              </v-btn>
              <p class="mt-2"><a @click="forgot">Forgot your password?</a></p>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="10">
              <OrDivider />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="10" class="text-center">
              <p class="caption">Connect with:</p>
              <div class="d-flex justify-center align-center">
                <v-btn
                  class="mx-2"
                  fab
                  depressed
                  small
                  color="deep-orange darken-3"
                  @click="loginSocial('google-oauth2')"
                >
                  <v-icon color="white">mdi-google</v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  depressed
                  small
                  color="blue darken-2"
                  @click="loginSocial('linkedin')"
                >
                  <v-icon color="white">mdi-linkedin</v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  depressed
                  small
                  color="grey"
                  @click="loginSocial('apple')"
                >
                  <v-icon color="white">fab fa-apple</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="10" class="text-center">
              <p class="caption">
                Don't have an account yet? <a @click="signup">Sign up now!</a>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import OrDivider from '../OrDivider'
import { Utils } from '@/lib/Utils'

export default {
  name: 'Login',
  components: { OrDivider },
  data() {
    return {
      connection: 'Username-Password-Authentication',
      loginForm: true,
      email: '',
      password: '',
      rememberMe: false,
      message: '',
      messageSuccess: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      isPWA: 'settings/isPWA',
      selectedIata: 'settings/selectedIata'
    }),
    emailErrorMessages() {
      if (!this.$v.email.$dirty) {
        return []
      }
      const errors = []
      if (!this.$v.email.required) {
        errors.push('Email is required.')
      }
      if (!this.$v.email.email) {
        errors.push('Email is not in a supported format.')
      }
      return errors
    },
    emailIcon() {
      return Utils.inputValidationIcon(this.$v.email)
    },
    passwordErrorMessages() {
      if (!this.$v.password.$dirty) {
        return []
      }
      const errors = []
      if (!this.$v.password.required) {
        errors.push('Password is required')
      }
      return errors
    },
    passwordIcon() {
      return Utils.inputValidationIcon(this.$v.password)
    }
  },
  created() {
    this.rememberMe = false
    this.setRememberMe()
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  methods: {
    /*
    continueAsGuest() {
      const iata = this.selectedIata
        ? this.selectedIata
        : constants.defaultAirport
      this.$router.push('/airport/' + iata)
      this.$emit('close', true)
    },
    */
    forgot() {
      this.$emit('forgot', true)
    },
    signup() {
      this.$emit('signup', true)
    },
    submitLogin() {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.loading = true
        const params = this.selectedIata
          ? { goto: `/airport/${this.selectedIata}` }
          : null
        this.$auth
          .login(this.email, this.password, params)
          .then((err, data) => {
            if (err) {
              this.setMessage(false, err.description)
              this.loading = false
            }
          })
      }
    },
    loginSocial(type) {
      const params = this.selectedIata
        ? { goto: `/airport/${this.selectedIata}` }
        : null
      this.$auth.loginSocial(type, params).then(function() {})
    },
    setMessage(success, message) {
      this.messageSuccess = success
      this.message = message
    },
    setRememberMe() {
      // Needs to be accessed by a plugin so avoid vuex, use cookie instead
      document.cookie = 'AV2_rememberMe=' + this.rememberMe
    }
  }
}
</script>
