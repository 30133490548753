import Vue from 'vue'
import io from 'socket.io-client'
import lifecycle from 'page-lifecycle'
import VueSocketIOExt from 'vue-socket.io-extended'

const socket = io(process.env.FLIGHT_SERVER_URL, {
  autoConnect: false
})

export default ({ store }) => {
  let timer = null

  const isPWA = () => {
    const mqStandAlone = '(display-mode: standalone)'
    return (
      /Android/i.test(navigator.userAgent) &&
      document.defaultView.matchMedia(mqStandAlone).matches
    )
  }

  const updateChannelState = (join) => {
    // If not connected to the socket, return and do not
    // attempt to emit join/leave requests.
    if (socket.disconnected && !isPWA()) {
      socket.connect()
    }

    timer = null

    const type = join === true ? 'join' : 'leave'

    try {
      const iataCode = store.state.settings.settings.airport.current.iata_code

      if (iataCode && !isPWA()) {
        socket.emit(type, iataCode + '-FLT')
        socket.emit(type, iataCode + '-POS')
        socket.emit(type, 'CLOCK-TIME')
      }
    } catch (e) {}
  }

  socket.on('reconnect', () => {
    // socket.io reconnect callback.  Connection was dropped and
    // successfully reconnected.  Need to rejoin CLOCK-TIME as well
    // as the airport channels if there is an active airport.
    // Observed this happening often after application has been in
    // background tab for extended period.
    updateChannelState(true)
  })

  lifecycle.addEventListener('statechange', function(event) {
    // console.log(
    //  'Lifecycle change from ' + event.oldState + ' to ' + event.newState
    // )

    if (event.newState === 'hidden') {
      // console.log('Set timer for 10 seconds')
      // Page is now hidden - let run for 10 seconds and if still hidden,
      // disconnect from socket.io so that messages don't continue to pile up
      timer = setTimeout(updateChannelState, 10000, false)
    } else if (
      event.oldState === 'hidden' &&
      (event.newState === 'passive' || event.newState === 'active')
    ) {
      // If timer is still active meaning the channels weren't yet dropped,
      // simply cancel the timer.  Otherwise, rejoin the channels.
      // Cancel any pending calls
      if (timer) {
        // console.log('Cleared timer')
        clearTimeout(timer)
        timer = null
      } else {
        updateChannelState(true)
      }
    }
  })

  // Mobile app is deprecated.  Don't attach $socket to Vue instance if detected.
  if (!isPWA()) Vue.use(VueSocketIOExt, socket, { store })
}
