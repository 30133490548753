<template>
  <v-autocomplete
    v-model="selectedAirport"
    :items="airports"
    item-value="iata_code"
    :item-text="(item) => item.iata_code + ' - ' + item.name"
    menu-props="auto"
    outlined
    filled
    :label="label"
    color="secondary"
    hide-details
    single-line
    prepend-inner-icon="mdi-magnify"
    :dense="dense"
    return-object
    :error-messages="errorMessages"
  >
    <template v-slot:selection="{ item }">
      <span>{{ item.iata_code }} - {{ item.name }}</span>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  name: 'SimpleAirportAutocompete',
  props: {
    errorMessages: {
      type: Array,
      default: () => {
        return []
      }
    },
    label: {
      type: String,
      default: null
    },
    setAirportFromStore: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedAirport: null,
      airports: []
    }
  },
  watch: {
    selectedAirport(val) {
      this.selectAirport(val)
    }
  },
  created() {
    fetch(this.$global.apiUrl + '/api/airports')
      .then((res) => res.json())
      .then((res) => {
        this.airports = res
        this.$emit('airportsLoaded', this.airports)
      })
      .catch(() => {})
  },
  mounted() {
    if (this.setAirportFromStore) {
      const store = this.$store.state.airport
      if (store.current) {
        this.selectedAirport = store.current
      }
    }
  },
  methods: {
    selectAirport(val) {
      if (val != null) {
        this.$emit('airportSelected', val)
      }
    }
  }
}
</script>
