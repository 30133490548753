import Vue from 'vue'
import { constants } from '../lib/Constants'

export default async function({ store, redirect }) {
  if (Vue.prototype.$auth.isAuthenticated) {
    const policy = await store.dispatch('user/getPrivacyPolicy')
    if (!policy || policy.length < 1) {
      redirect('/privacy')
    }

    let maxVersionAccepted = null

    for (const acceptedPolicy of policy) {
      const policyVer = +acceptedPolicy.version
      if (maxVersionAccepted == null || policyVer > maxVersionAccepted)
        maxVersionAccepted = policyVer
    }

    const currentPolicyVersion = +constants.privacyPolicyVersion

    if (maxVersionAccepted < currentPolicyVersion) {
      redirect('/privacy')
    }
  }
}
