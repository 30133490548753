<template>
  <v-app>
    <v-main>
      <v-container>
        <nuxt />
        <CookieControl>
          <template v-slot:bar>
            <h3>Cookie Consent</h3>
            <p>
              Cookies are important to the proper functioning of the Airport
              Viewer website and service. We use cookies and similar methods to
              recognize visitors and remember their preferences, to improve your
              experience (e.g., to remember log-in details), to collect
              statistics about how the site is used, and, in certain cases, to
              deliver content tailored to your interests. Our Cookie Policy is
              part of our Privacy Policy; see our
              <a href="/privacy" style="color: white;">Privacy Policy</a> for
              information. By choosing to Accept Cookies you consent to the
              these these methods by us and third parties. The Manage Cookies
              Preferences button allows you to chose which types of cookies you
              accept. You may withdraw or modify your consent via the Manage
              Cookie Preferences button at any time.
            </p>
          </template>
        </CookieControl>
      </v-container>
    </v-main>
    <v-footer app>
      <span>&copy; {{ new Date().getFullYear() }} Mosaic ATM</span>
    </v-footer>
  </v-app>
</template>

<script>
/* global dataLayer */

function gtag() {
  dataLayer.push(arguments)
}

export default {
  middleware: ['isReady', 'boostrap', 'googleAnalytics'],
  mounted() {
    if (process.env.ENABLE_GOOGLE_ANALYTICS) {
      window.dataLayer = window.dataLayer || []

      gtag('js', new Date())

      gtag('config', process.env.GOOGLE_ANALYTICS_ID)
    }
  }
}
</script>
