<template>
  <v-card tile flat>
    <h2 class="text-center dialog-title">Sign Up</h2>
    <v-card-text class="text--primary">
      <v-form ref="signupForm" v-model="signupForm">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" sm="10">
              <h3>Email</h3>
              <v-text-field
                v-model="email"
                type="email"
                color="secondary"
                :append-icon="emailIcon"
                :error-messages="emailErrorMessages"
                placeholder="name@example.com"
                filled
                required
                outlined
                dense
                data-cy="signup-email"
                @keydown.enter="submitSignup"
                @focus="$v.email.$reset()"
                @blur="$v.email.$touch()"
              ></v-text-field>

              <h3>Password</h3>
              <PasswordInput
                ref="password"
                v-model="password"
                data-cy="signup-password"
                @enter="submitSignup"
                @ready="(r) => (passwordReady = r)"
              />

              <h3>Confirm Password</h3>
              <PasswordInput
                ref="password2"
                v-model="password2"
                data-cy="newpassword2"
                :tooltip="false"
                :match="password"
                @ready="(r) => (password2Ready = r)"
              />

              <h3>Default Airport</h3>
              <SimpleAirportAutocomplete
                label="Select Airport"
                data-cy="signup-defaultairport"
                :error-messages="defaultAirportErrorMessages"
                :dense="true"
                @airportsLoaded="handleAirportsLoaded"
                @airportSelected="handleAirportSelection"
              />

              <v-checkbox
                v-model="tosCheck"
                data-cy="signup-toscheck"
                :error-messages="tosErrorMessages"
              >
                <template v-slot:label>
                  <div>
                    I have read and accept the Mosaic ATM
                    <a target="_blank" href="/terms" @click.stop>
                      terms of service
                    </a>
                  </div>
                </template>
              </v-checkbox>

              <v-alert
                v-if="message"
                :color="messageSuccess ? 'green' : 'red'"
                colored-border
                elevation="1"
                border="left"
              >
                {{ message }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="6" class="text-center">
              <v-btn
                class="text-capitalize"
                block
                depressed
                data-cy="signup-submit"
                color="primary"
                :loading="loading"
                @click="submitSignup"
              >
                Sign Up
              </v-btn>
            </v-col>
          </v-row>
          <!--
          <v-row justify="center">
            <v-col cols="12" sm="10">
              <OrDivider />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <p class="caption">Connect with:</p>
              <div class="d-flex justify-center align-center">
                <v-btn
                  class="mx-2"
                  fab
                  depressed
                  small
                  color="deep-orange darken-3"
                  @click="signupSocial('google-oauth2')"
                >
                  <v-icon color="white">mdi-google</v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  depressed
                  small
                  color="blue darken-2"
                  @click="signupSocial('linkedin')"
                >
                  <v-icon color="white">mdi-linkedin</v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  depressed
                  small
                  color="grey"
                  @click="signupSocial('apple')"
                >
                  <v-icon color="white">fab fa-apple</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          -->
          <v-row>
            <v-col class="text-center">
              <p class="caption">
                Already have an account? <a @click="login">Login now!</a>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
import { Utils } from '../../lib/Utils'
// import OrDivider from '../OrDivider'
import PasswordInput from '../PasswordInput'
import { constants } from '../../lib/Constants'
import SimpleAirportAutocomplete from './SimpleAirportAutocomplete'

const tosCheckValidator = (value) => {
  // The 'required' validator just nullchecks the value, so a false value actually passes validation.
  // So we need to check for an actual boolean true.
  return value === true
}

export default {
  name: 'Signup',
  components: { SimpleAirportAutocomplete, PasswordInput },
  data() {
    return {
      signupForm: true,
      email: '',
      password: '',
      password2: '',
      defaultAirport: '',
      tosCheck: false,
      message: '',
      messageSuccess: false,
      loading: false,
      passwordReady: false,
      password2Ready: false
    }
  },
  computed: {
    ready() {
      return this.passwordReady && this.password2Ready
    },
    defaultAirportErrorMessages() {
      const errors = []
      if (!this.$v.email.$dirty) {
        return errors
      }
      if (!this.$v.defaultAirport.required) {
        errors.push('A default airport.')
      }

      return errors
    },
    emailErrorMessages() {
      if (!this.$v.email.$dirty) {
        return []
      }
      const errors = []
      if (!this.$v.email.required) {
        errors.push('Email is required.')
      }
      if (!this.$v.email.email) {
        errors.push('Email is not in a supported format.')
      }
      return errors
    },
    emailIcon() {
      return Utils.inputValidationIcon(this.$v.email)
    },
    tosErrorMessages() {
      if (!this.$v.tosCheck.$dirty) {
        return []
      }
      const errors = []
      if (!this.$v.tosCheck.tosCheckValidator) {
        errors.push('You must agree with our Terms of Service')
      }
      return errors
    }
  },
  validations: {
    defaultAirport: {
      required
    },
    email: {
      required,
      email
    },
    tosCheck: {
      tosCheckValidator
    }
  },
  methods: {
    continueAsGuest() {
      this.$router.push('/map')
      this.$emit('close', true)
    },
    login() {
      this.$emit('login', true)
    },
    handleAirportSelection(airport) {
      this.defaultAirport = airport
    },
    handleAirportsLoaded(airports) {
      // noop
    },
    submitSignup() {
      this.$v.$touch()
      this.$refs.password.touch()
      if (!this.$v.$error && this.ready) {
        this.loading = true
        // nullcheck default airport
        const _defaultAirport = this.defaultAirport || { iata_code: '' }
        this.preSignup()
        this.$auth
          .signup(this.email, this.password, {
            defaultAirport: _defaultAirport.iata_code
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    signupSocial(type) {
      // force Agree with TOS
      this.$v.tosCheck.$touch()
      if (!this.$v.tosCheck.$error) {
        this.preSignup()
        this.$auth.loginSocial(type)
      }
    },
    preSignup() {
      window.localStorage.setItem(constants.storageKeys.justSignedUp, 'true')
    },
    setMessage(success, message) {
      this.messageSuccess = success
      this.message = message
    }
  }
}
</script>
