import Vue from 'vue'
export const EventBus = new Vue()
export const Events = {
  data: {
    airportsLoaded: 'data-airports-loaded',
    userLoaded: 'data-user-loaded'
  },
  navigation: {
    setMenuTitle: 'navigation-set-menu-title',
    openSignupDialog: 'open-signup-dialog',
    openLoginDialog: 'open-login-dialog'
  },
  flights: {
    positions: 'flights-positions',
    updates: 'flights-updates'
  },
  airportSelected: 'airport-selected',
  map: {
    controls: {
      home: 'map-controls-home',
      plus: 'map-controls-current-plus',
      minus: 'map-controls-current-minus'
    },
    click: 'map-click',
    settings: {
      loaded: 'map-settings-loaded'
    }
  }
}
