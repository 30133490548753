<template>
  <v-app-bar color="primary" flat app>
    <v-toolbar-title class="white--text" style="font-variant: small-caps;">
      <nuxt-link
        v-if="!isHomePage && !$store.getters['settings/isPWA']"
        to="/"
        class="mr-4"
      >
        <img src="/home.svg" alt="home" style="transform: translateY(-3px)" />
      </nuxt-link>

      <a @click="goToPreviousAirport">
        <img src="/group.svg" alt="map" />
      </a>

      <div
        v-if="!$vuetify.breakpoint.xsOnly"
        style="border-right: 1px white solid; display: inline; margin: 0 25px;"
      />
      <div v-if="!$vuetify.breakpoint.xsOnly" style="display: inline;">
        <a href="https://www.mosaicatm.com/" target="_blank">
          <img src="/mosaic-atm-white.png" height="25px;" alt="logo" />
        </a>
      </div>
    </v-toolbar-title>
    <v-spacer />
    <div>
      <LoginModal />
      <SignupModal />
    </div>
  </v-app-bar>
</template>
<script>
import LoginModal from '../components/landing/LoginModal'
import SignupModal from '../components/landing/SignupModal'
import { EventBus, Events } from '@/lib/Eventbus'

export default {
  components: { LoginModal, SignupModal },
  computed: {
    isHomePage() {
      return this.$route.name === 'index'
    }
  },
  methods: {
    goToPreviousAirport() {
      this.$store.commit(
        'settings/setSelectedIata',
        this.$store.getters['settings/previousAirport']
      )
      EventBus.$emit(Events.navigation.openLoginDialog)
    }
  }
}
</script>
