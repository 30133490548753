<style scoped>
.auth0-lock.auth0-lock .auth0-lock-header {
  position: inherit;
}
</style>
<template>
  <v-app light>
    <HeaderLoggedIn v-if="$auth.isAuthenticated" />
    <HeaderLoggedOut v-else />
    <v-main>
      <!-- Same as landing.vue layout but without the CookieControl for consent such that a user
      can load the Privacy Policy via the link from the Cookie Consent. -->
      <nuxt />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import HeaderLoggedOut from '../components/HeaderLoggedOut'
import HeaderLoggedIn from '../components/HeaderLoggedIn'
import Footer from '../components/Footer'

export default {
  middleware: ['isReady', 'boostrap', 'googleAnalytics'],
  components: { HeaderLoggedIn, HeaderLoggedOut, Footer },
  created() {
    if (this.$auth.user) {
      this.initAuthUser(this.$auth.user)
    }
  },
  methods: {
    ...mapActions({
      initAuthUser: 'user/initAuthUser'
    }),
    login() {
      this.$auth.loginWithRedirect()
    },
    logout() {
      this.$auth.logout()
    }
  }
}
</script>
