import Vue from 'vue'
import moment from 'moment'

const AGE_LIMIT = 60 * 2
const TIMEOUT_LIMIT = 60 * 1.5

export const state = () => ({
  clockDate: null,
  clockTime: null,
  clockTimeMs: null,
  selectedAirport: null,
  featurePositions: {
    arrivals: {},
    departures: {},
    noFp: {},
    gse: {},
    unknown: {}
  }
})

export const getters = {
  currentTime(state, xsOnly) {
    if (state.clockTime) {
      if (xsOnly) {
        return state.clockTime + ' UTC'
      } else {
        return state.clockDate + ' ' + state.clockTime + ' UTC'
      }
    } else {
      return ''
    }
  },
  currentDate(state) {
    return state.clockDate
  }
}

/**
 *
 * @param {{ featurePositions }} state
 * @param {array<string>} fromLayers
 * @param {string} flightKey
 */
const deleteFlight = (state, fromLayers, flightKey) => {
  fromLayers.forEach((l) => {
    Vue.delete(state.featurePositions[l], flightKey)
  })
}

/**
 * @param state
 * @param {{departureAirport: string, arrivalAirport: string, flightKey: string, targetType: string, stale: boolean}} flight
 * @param {boolean} parseLayer
 */
const addFlight = (state, { flight, parseLayer }) => {
  flight.stale = false

  if (flight.targetType === 'aircraft') {
    /**
     * User does not have feature to parse flights just add to noFp
     */
    if (parseLayer === false) {
      deleteFlight(
        state,
        ['arrivals', 'departures', 'gse', 'unknown'],
        flight.flightKey
      )
      Vue.set(state.featurePositions.noFp, flight.flightKey, flight)
      return
    }

    /**
     * flight is departure flight
     */
    if (
      typeof flight.departureAirport !== 'undefined' &&
      flight.departureAirport === state.selectedAirport
    ) {
      deleteFlight(
        state,
        ['arrivals', 'gse', 'noFp', 'unknown'],
        flight.flightKey
      )
      Vue.set(state.featurePositions.departures, flight.flightKey, flight)

      /**
       * flight is an arrival
       */
    } else if (
      typeof flight.arrivalAirport !== 'undefined' &&
      flight.arrivalAirport === state.selectedAirport
    ) {
      deleteFlight(
        state,
        ['departures', 'gse', 'noFp', 'unknown'],
        flight.flightKey
      )
      Vue.set(state.featurePositions.arrivals, flight.flightKey, flight)

      /**
       * flight is a noFp
       */
    } else {
      deleteFlight(
        state,
        ['arrivals', 'departures', 'gse', 'unknown'],
        flight.flightKey
      )
      Vue.set(state.featurePositions.noFp, flight.flightKey, flight)
    }
  } else if (flight.targetType === 'gse') {
    deleteFlight(
      state,
      ['arrivals', 'departures', 'noFp', 'unknown'],
      flight.flightKey
    )
    Vue.set(state.featurePositions.gse, flight.flightKey, flight)
  } else {
    deleteFlight(
      state,
      ['arrivals', 'departures', 'noFp', 'gse'],
      flight.flightKey
    )
    Vue.set(state.featurePositions.unknown, flight.flightKey, flight)
  }
}

export const mutations = {
  setDate(state, date) {
    Vue.set(state, 'clockDate', date)
  },
  setTime(state, { time, timeMs }) {
    Vue.set(state, 'clockTime', time)
    Vue.set(state, 'clockTimeMs', timeMs)
  },
  addFeature(state, { feature, parseLayer }) {
    addFlight(state, { flight: feature, parseLayer })
  },
  updateFeature(state, { featureLayer, feature }) {
    Vue.set(state.featurePositions[featureLayer], feature.flightKey, feature)
  },
  setFeatures(state, { featureType, features }) {
    Vue.set(state.featurePositions, featureType, features)
  },
  deleteFeature(state, { featureType, featureId }) {
    Vue.delete(state.featurePositions[featureType], featureId)
  },
  setSelectedAirport(state, airport) {
    Vue.set(state, 'selectedAirport', 'K' + airport)
  },
  setFeatureStale(state, { featureType, featureKey }) {
    if (state.featurePositions[featureType][featureKey]) {
      Vue.set(state.featurePositions[featureType][featureKey], 'stale', true)
    }
  }
}

export const actions = {
  socket_message({ state, rootGetters, commit }, message) {
    // Does user have dep/dest filter feature?
    const filterByOrgDest = rootGetters['user/featureAvailable'](
      'displayOrgDest'
    )

    const msg = JSON.parse(message)

    // flight-server sends messages with 'popup' attribute if they're to be shown
    // as error popups on UI and need to be acknowledged.
    if ('popup' in msg) {
      Vue.toasted.clear()
      Vue.toasted.show(msg.popup, {
        position: 'top-center',
        type: msg.type != null ? msg.type : 'info',
        action: {
          text: 'OK',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    }

    /**
     * Current Time Message
     */
    if ('currentTime' in msg) {
      const clockTimeMs = msg.currentTime

      const currentMoment = moment.utc(msg.currentTime)
      commit('setDate', currentMoment.format('MM/DD'))
      commit('setTime', {
        time: currentMoment.format('HH:mm:ss'),
        timeMs: clockTimeMs
      })

      /**
       * flight position message
       */
    } else if ('webFlightPositionList' in msg) {
      msg.webFlightPositionList.forEach((p) => {
        if (typeof p.acid === 'undefined') {
          return
        }

        // if user has filter feature
        if (filterByOrgDest) {
          let isArrivalFlight = false
          let isDepartureFlight = false

          Object.keys(state.featurePositions.arrivals).forEach((k) => {
            if (state.featurePositions.arrivals[k].flightKey === p.flightKey) {
              isArrivalFlight = true
            }
          })

          if (isArrivalFlight === false) {
            Object.keys(state.featurePositions.departures).forEach((k) => {
              if (
                state.featurePositions.departures[k].flightKey === p.flightKey
              ) {
                isDepartureFlight = true
              }
            })
          }

          if (isArrivalFlight) {
            commit('updateFeature', { featureLayer: 'arrivals', feature: p })
          } else if (isDepartureFlight) {
            commit('updateFeature', { featureLayer: 'departures', feature: p })
          } else {
            commit('addFeature', { feature: p })
          }
          // User does not have filter feature just add flight
        } else {
          commit('addFeature', { feature: p, parseLayer: false })
        }
      })

      /**
       * Flight plan message
       */
    } else if ('webFlightList' in msg) {
      msg.webFlightList.forEach((p) => {
        if (typeof p.acid === 'undefined') {
          return
        }

        commit('addFeature', { feature: p, parseLayer: filterByOrgDest })
      })
    }
  },
  clearFlights({ commit }) {
    commit('setFeatures', { featureType: 'arrivals', features: {} })
    commit('setFeatures', { featureType: 'departures', features: {} })
    commit('setFeatures', { featureType: 'noFp', features: {} })
    commit('setFeatures', { featureType: 'gse', features: {} })
    commit('setFeatures', { featureType: 'unknown', features: {} })
  },
  clearDeadFeatures({ commit, state }) {
    Object.keys(state.featurePositions.arrivals).forEach((k) => {
      const f = state.featurePositions.arrivals[k]
      const diff = moment().unix() - f.updateTime / 1000

      if (diff > AGE_LIMIT) {
        commit('deleteFeature', { featureType: 'arrivals', featureId: k })
      }

      if (diff >= TIMEOUT_LIMIT) {
        commit('setFeatureStale', { featureType: 'arrivals', featureKey: k })
      }
    })

    Object.keys(state.featurePositions.departures).forEach((k) => {
      const f = state.featurePositions.departures[k]
      const diff = moment().unix() - f.updateTime / 1000

      if (diff > AGE_LIMIT) {
        commit('deleteFeature', { featureType: 'departures', featureId: k })
      }

      if (diff >= TIMEOUT_LIMIT) {
        commit('setFeatureStale', { featureType: 'departures', featureKey: k })
      }
    })

    Object.keys(state.featurePositions.noFp).forEach((k) => {
      const f = state.featurePositions.noFp[k]
      const diff = moment().unix() - f.updateTime / 1000

      if (diff > AGE_LIMIT) {
        commit('deleteFeature', { featureType: 'noFp', featureId: k })
      }

      if (diff >= TIMEOUT_LIMIT) {
        commit('setFeatureStale', { featureType: 'noFp', featureKey: k })
      }
    })

    Object.keys(state.featurePositions.gse).forEach((k) => {
      const f = state.featurePositions.gse[k]
      const diff = moment().unix() - f.updateTime / 1000

      if (diff > AGE_LIMIT) {
        commit('deleteFeature', { featureType: 'gse', featureId: k })
      }

      if (diff >= TIMEOUT_LIMIT) {
        commit('setFeatureStale', { featureType: 'gse', featureKey: k })
      }
    })

    Object.keys(state.featurePositions.unknown).forEach((k) => {
      const f = state.featurePositions.gse[k]

      // Mike H - seeing issue here but only for this unknown,
      // not gse, noFp, departures, arrivals:
      //
      // Uncaught TypeError: Cannot read property 'updateTime' of undefined
      // Adding a check for now but need to address if this type of feature
      // is supposed to have the updateTime?

      if (f != null && f.updateTime) {
        const diff = moment().unix() - f.updateTime / 1000

        if (diff > AGE_LIMIT) {
          commit('deleteFeature', { featureType: 'unknown', featureId: k })
        }

        if (diff >= TIMEOUT_LIMIT) {
          commit('setFeatureStale', { featureType: 'unknown', featureKey: k })
        }
      }
    })
  }
}
