<style scoped>
a.footer-link {
  color: white;
  text-decoration: none;
  line-height: 2em;
}
</style>
<template>
  <v-footer
    dark
    padless
    tile
    :style="
      absolute
        ? 'position: absolute; bottom: 0; width: 100%; background-color: rgba(39,39,39,.8)'
        : ''
    "
  >
    <v-container>
      <v-row no-gutters>
        <v-col
          :class="{ 'mb-4': $vuetify.breakpoint.smAndDown }"
          cols="12"
          md="8"
        >
          <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters>
            <v-col v-for="(item, i) in footerItems" :key="i" cols="4" lg="4">
              <router-link v-if="item.link" class="footer-link" :to="item.link">
                {{ item.text }}
                <img
                  v-if="item.img"
                  :src="item.img"
                  style="height: 15px; padding-top: 2px;"
                  :alt="item.text"
                />
              </router-link>
            </v-col>
          </v-row>
          <v-row v-else no-gutters>
            <template v-for="(item, i) in footerItems">
              <v-col v-if="item.link" :key="i" cols="6" lg="6">
                <router-link class="footer-link" :to="item.link">
                  {{ item.text }}
                  <img
                    v-if="item.img"
                    :src="item.img"
                    style="height: 15px; padding-top: 2px;"
                    :alt="item.text"
                  />
                </router-link>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col cols="6" md="2">
          <span style="font-size: 12px;">
            &copy; 2018 - {{ new Date().getFullYear() }} <br />Mosaic ATM, Inc.
          </span>
        </v-col>
        <v-col cols="6" md="2">
          <div>
            <v-img
              src="/mosaic-atm-white@2x.png"
              eager
              contain
              height="50"
              class="mb-3"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  props: {
    absolute: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      footerItems: [
        { text: 'Contact', icon: 'mdi-account-multiple', link: '/contact' },
        { text: 'FAQ', icon: 'mdi-faq', link: '/faq' },
        { text: 'About', icon: 'mdi-folder', link: '/about' },
        { text: 'Terms of Service', icon: 'mdi-star', link: '/terms' },
        { text: 'Privacy Policy', icon: 'mdi-history', link: '/privacy' },
        {
          text: 'CCPA Opt-Out',
          img: '/privacyoptions.svg',
          link: '/personal-info'
        }
      ]
    }
  }
}
</script>
