<style>
.v-overlay--active {
  backdrop-filter: blur(3px);
}
</style>
<template>
  <v-dialog
    v-if="showButton"
    v-model="loginDialog"
    content-class="dialog-maxwidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed color="primary" v-bind="attrs" v-on="on">
        LOGIN
      </v-btn>
    </template>
    <v-toolbar dense flat tile>
      <v-spacer />
      <v-btn small icon @click="loginDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <Login
      v-if="form === 'login'"
      @close="loginDialog = false"
      @forgot="toggleForgot"
      @signup="toggleSignup"
    />
    <ForgotPassword
      v-else-if="form === 'forgot'"
      @forgot="toggleForgot"
      @signup="toggleSignup"
    />
  </v-dialog>
</template>
<script>
import { EventBus, Events } from '../../lib/Eventbus'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
export default {
  name: 'LoginModal',
  components: { Login, ForgotPassword },
  data() {
    return {
      loginDialog: false,
      // 'login' or 'forgot'
      form: 'login'
    }
  },
  computed: {
    showButton() {
      const pages = ['login', 'signup', 'forgot']
      return !pages.includes(this.$route.name)
    }
  },
  watch: {
    loginDialog() {
      // default to login form whenever the dialog opens
      if (this.loginDialog) {
        this.form = 'login'
      }
    }
  },
  created() {
    EventBus.$on(Events.navigation.openLoginDialog, () => {
      this.form = 'login'
      this.loginDialog = true
    })
  },
  beforeDestroy() {
    EventBus.$off(Events.navigation.openLoginDialog)
  },
  methods: {
    toggleForgot(data) {
      if (data) {
        this.form = 'forgot'
      } else {
        this.form = 'login'
      }
    },
    toggleSignup(data) {
      EventBus.$emit(Events.navigation.openSignupDialog)
      this.loginDialog = false
    }
  }
}
</script>
