import Vue from 'vue'
import auth0 from 'auth0-js'
import { constants } from '@/lib/Constants'

const DEFAULT_GOTOROUTE = (o) => {
  window.history.replaceState(o, document.title, window.location.pathname)
  window.location.href = o
}

const _BASE_WEBAUTH_OPTIONS = {
  overrides: {
    __tenant: process.env.AUTH_TENANT,
    __token_issuer: 'https://' + process.env.AUTH_DOMAIN + '/'
  },
  domain: process.env.AUTH_DOMAIN,
  clientID: process.env.CLIENT_ID,
  audience: process.env.AUDIENCE,
  responseType: 'token id_token'
}

// Used for prefixing/namespacing localStorage keys
const LS_PREFIX = 'AV2_'
// Force token refresh X seconds before it actually expires
const REFRESH_THRESHOLD_SECONDS = 10 * 60 // 10 minutes
// used for removing cookies (set expiration to earliest possible date)
const EPOCH_START = 'Thu, 01 Jan 1970 00:00:00 GMT'
// cookie expiration threshold

let instance

export const getInstance = () => instance

export const useAuth0 = ({
  goToRoute = DEFAULT_GOTOROUTE,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  instance = new Vue({
    data() {
      return {
        connection: 'Username-Password-Authentication',
        loading: true,
        isAuthenticated: false,
        user: {},
        domainUrl: null,
        redirectUri: null,
        webAuth: null,
        error: null,
        rememberMe: true
      }
    },
    async created() {
      // check for rememberme cookie
      if (this.getCookie('rememberMe') === 'true') {
        this.rememberMe = true
      }
      this.domainUrl = options.domain
      this.redirectUri = redirectUri
      const webAuthParams = Object.assign(
        {
          redirectUri
        },
        _BASE_WEBAUTH_OPTIONS
      )
      this.webAuth = new auth0.WebAuth(webAuthParams)

      const hashValue = window.location.hash

      let redirect = false

      try {
        if (
          hashValue.includes('id_token=') &&
          hashValue.includes('access_token=') &&
          hashValue.includes('state=')
        ) {
          const tokensString = hashValue.substring(1, hashValue.length) // remove the # in the string
          const searchParams = new URLSearchParams(tokensString)
          this.updateAuthTokens(Object.fromEntries(searchParams))
          redirect = true
        }
      } catch (e) {
        this.error = e
        goToRoute('/login')
      } finally {
        await this.checkAuth()
        await this.getUserInfo()
        this.loading = false
      }

      if (redirect) {
        const userMetadata = this.user[
          process.env.METADATA_NAMESPACE + 'user_metadata'
        ]

        let defaultAirport = null
        if (userMetadata && userMetadata.defaultAirport) {
          defaultAirport = userMetadata.defaultAirport
        } else {
          defaultAirport = constants.defaultAirport
        }

        // check for a goto url query. If one does not exist then set a redirect to the default airport
        if (!window.location.search.includes('goto')) {
          window.location = '/airport/' + defaultAirport
        } else {
          // eslint-disable-next-line nuxt/no-globals-in-created
          window.location = '/' + window.location.search
        }

        // eslint-disable-next-line nuxt/no-globals-in-created
        window.location.hash = ''
      }
    },
    methods: {
      // Login with manual username and password
      login(email, password, params) {
        const redirectUri = this.buildRedirectUri(params)

        return new Promise((resolve) => {
          this.webAuth.login(
            {
              realm: this.connection,
              username: email,
              password,
              redirectUri
            },
            resolve
          )
        })
      },
      // OAuth2 Social login
      loginSocial(type, params) {
        const redirectUri = this.buildRedirectUri(params)
        return new Promise((resolve, reject) => {
          this.webAuth.authorize(
            {
              connection: type,
              redirectUri
            },
            function(err, result) {
              if (err) {
                return reject(err)
              }

              return resolve(result)
            }
          )
        })
      },
      // Send forgot password email
      forgotPassword(email) {
        return new Promise((resolve) => {
          this.webAuth.changePassword(
            {
              connection: this.connection,
              email
            },
            resolve
          )
        })
      },
      // Signup with email, password, and set default airport
      signup(email, password, userMetadata) {
        return new Promise((resolve, reject) => {
          this.webAuth.redirect.signupAndLogin(
            {
              connection: this.connection,
              email,
              password,
              user_metadata: userMetadata || {}
            },
            function(err, result) {
              if (err) {
                return reject(err)
              }

              return resolve(result)
            }
          )
        })
      },
      // Do not call this method directly. Use getUser() instead.
      // Retrieves latest userInfo from auth0
      _getUserInfo(accessToken) {
        return new Promise((resolve, reject) => {
          this.webAuth.client.userInfo(accessToken, function(err, user) {
            if (err) {
              reject(err)
            }
            if (user) {
              resolve(user)
            }
          })
        })
      },
      // Do not call this method directly. Use refreshTokens() instead.
      // Refresh tokens
      _checkSession() {
        return new Promise((resolve) => {
          /*
           * I really don't know why, but checkSession response is missing 'audience' and 'scope' fields,
           * unless we explicitly add them as options.
           */
          this.webAuth.checkSession(
            {
              audience: _BASE_WEBAUTH_OPTIONS.audience,
              scope: 'openid profile email'
            },
            function(err, authResult) {
              if (err) {
                resolve(err)
              }
              resolve(authResult)
            }
          )
        })
      },
      async refreshTokens() {
        await this._checkSession()
          .then((authResult) => {
            this.updateAuthTokens(authResult)
            // might as well refresh user info
            // this method is async so it won't hold up anything
            this.getUserInfo()
          })
          .catch((err) => {
            this.error = err
          })
      },
      async checkAuth() {
        const expiry = parseInt(this.getCookie('tokensExpiry'))
        if (!expiry || expiry === 0) {
          // missing expiration time, user probably never logged in
          this.isAuthenticated = false
          // ensure webauth session is cleared
          this.clearAuth0Cookies()
          return
        }
        const tenMinutesBeforeExpiry = expiry - REFRESH_THRESHOLD_SECONDS
        const now = Math.floor(new Date().getTime() / 1000)
        if (now > tenMinutesBeforeExpiry) {
          // token is expired, or about to expire, try to refresh it:
          await this.refreshTokens()
        }

        // token OK, set authentication state
        this.isAuthenticated = true
      },
      async getUserInfo() {
        const accessToken = this.getCookie('accessToken')
        if (!accessToken) {
          // missing access token, user probably never logged in
          return
        }
        await this._getUserInfo(accessToken)
          .then((user) => {
            this.user = user
          })
          .catch((err) => {
            this.error = err
            this.logout({ sendToLogin: true })
          })
      },
      async getTokenSilently(o) {
        if (this.loading === true) {
          return
        }
        if (this.rememberMe) {
          await this.checkAuth()
        }
        return this.getCookie('accessToken')
      },
      logout(o) {
        this.rmCookie('accessToken')
        this.rmCookie('idToken')
        this.rmCookie('tokensExpiry')

        let redirectUri = this.redirectUri
        if (o && o.sendToLogin) {
          redirectUri += '?goto=/login'
        }
        this.webAuth.logout({
          returnTo: redirectUri
        })
      },
      updateAuthTokens(data) {
        // set remember me to always true for now.  User should stay logged in
        const rememberme = this.getCookie('rememberMe') === 'true'

        // Expiration for the cookie itself, NOT the token expiration:
        let expires = null
        // leaving expires null forces cookie to delete at end of session
        if (rememberme) {
          expires = new Date()
          // OK to overflow, the Date object handles it.
          expires.setMonth(expires.getMonth() + 1)
        }
        // update remember cookie with new expiration
        this.setCookie('rememberMe', rememberme, expires)

        // NOTE, data is sometimes camel case, sometimes underscores. check for both.
        this.setCookie(
          'accessToken',
          data.access_token || data.accessToken,
          expires
        )
        this.setCookie('idToken', data.id_token || data.idToken, expires)
        const now = Math.floor(new Date().getTime() / 1000)
        const expiresIn = data.expires_in || data.expiresIn
        const tokensExpiry = now + parseInt(expiresIn)
        this.setCookie('tokensExpiry', tokensExpiry, expires)
      },
      clearAuth0Cookies() {
        const kvps = document.cookie
          .split('; ')
          .filter(
            (row) =>
              row.startsWith('com.auth0.auth') ||
              row.includes(process.env.AUTH_DOMAIN)
          )

        kvps.map((kvp) => {
          const key = kvp.split('=')[0]
          document.cookie = key + '=; expires=' + EPOCH_START
        })
      },
      // set cookie
      setCookie(key, value, expires) {
        const name = LS_PREFIX + key
        let str = name + '=' + value
        if (expires) {
          str = str + '; expires=' + expires.toUTCString()
        }
        // Add Path
        str = str + '; Path=/'

        // Add SameSite strict flag
        str = str + '; SameSite=Strict'
        document.cookie = str
      },
      getCookie(key) {
        const name = LS_PREFIX + key
        const kvp = document.cookie
          .split('; ')
          .find((row) => row.startsWith(name))

        if (kvp) {
          const value = kvp.split('=')[1]
          return value.trim()
        }
        return undefined
      },
      rmCookie(key) {
        const name = LS_PREFIX + key
        document.cookie = name + '=; path=/; expires=' + EPOCH_START
      },
      buildRedirectUri(params) {
        // Convert params to URL query
        const redirectQuery = params
          ? Object.keys(params)
              .map((key) => key + '=' + params[key])
              .join('&')
          : null

        // If necessary, inject query into redirect URI
        return redirectQuery
          ? this.redirectUri + `/?${redirectQuery}`
          : this.redirectUri
      }
    }
  })

  return instance
}

export const Auth0WebAuthPlugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
