import Vue from 'vue'

export default function({ $axios, redirect }) {
  $axios.onRequest((config) => {
    // console.log('making new request', config)
  })

  $axios.onResponse((response) => {
    // console.log('response', response)
  })

  $axios.onError((error) => {
    // console.error('axios.js error', error)
    if (error.response.status === 401) {
      Vue.prototype.$auth.logout({ sendToLogin: true })
    }
  })

  $axios.onRequestError(() => {
    // console.log('request error', error)
  })

  $axios.onResponseError(() => {
    // console.log('response error', error)
  })
}
