export const constants = {
  termsOfServiceVersion: '2.0',
  privacyPolicyVersion: '2.0',
  defaultAirport: 'ATL',
  storageKeys: {
    isPWA: 'isPWA',
    justSignedUp: 'justSignedUp',
    previousAirport: 'previousAirport'
  },
  subscriptions: {
    free: {
      title: 'Free',
      price: '0.00',
      code: 'free',
      requiresContact: false
    },
    basic: {
      title: 'Basic Plan',
      price: '4.99',
      code: 'basic',
      requiresContact: false
    },
    professional: {
      title: 'Enthusiast',
      price: '9.99',
      code: 'professional',
      requiresContact: false
    },
    commercial_individual: {
      title: 'Commercial - Individual',
      price: '99.00',
      code: 'commercial_individual',
      requiresContact: false
    },
    commercial_shared: {
      title: 'Commercial - Shared',
      price: '249.00',
      code: 'commercial_shared',
      requiresContact: false
    },
    commercial_custom: {
      title: 'Commercial - Custom',
      price: 'Contact Us',
      code: 'commercial_custom',
      requiresContact: false
    },
    enterprise: {
      title: 'Enterprise',
      price: 'Contact Us',
      code: 'enterprise',
      requiresContact: true
    }
  },
  dataBlockFeatures: [
    {
      blockTitle: false,
      items: [
        {
          feature: 'Ads Removed',
          enabled: [false, true, true, true, true, true, true]
        },
        {
          feature: 'Airports',
          enabled: [
            'Up to 40 U.S. airports',
            'Up to 40 U.S. airports',
            'Up to 40 U.S. airports',
            'Up to 40 U.S. airports',
            'Up to 40 U.S. airports',
            'Up to 40 U.S. airports',
            'Custom'
          ]
        },
        {
          feature: 'Number of Simultaneous Sessions / Browsers',
          enabled: ['1', '1', '1', '2', '2', 'Custom', 'Custom / Unlimited']
        },
        {
          feature: 'Number of Individuals Permitted to Use Account',
          enabled: [
            'Individual User',
            'Individual User',
            'Individual User',
            'Individual User',
            'Shared',
            'Custom number of accounts',
            'Shared'
          ]
        },
        {
          feature: 'Commercial Use Allowed',
          enabled: [
            'No',
            'No',
            'Commercial use only by pilots whose primary job is to fly aircraft commercially and FAA/US Federal Government employees for official business (see Terms of Service)',
            'Yes',
            'Yes',
            'Yes',
            'Yes'
          ]
        },
        {
          feature: 'Maximum Usage per Day',
          enabled: [
            '30 minutes',
            '60 minutes',
            '2 hours',
            'Unlimited',
            'Unlimited',
            'Unlimited',
            'Unlimited'
          ]
        },
        {
          feature: 'Receipts Available',
          enabled: ['No', 'No', 'No', 'Yes', 'Yes', 'Yes/Invoiced', 'Invoiced']
        }
      ]
    },
    {
      blockTitle: 'Data Services',
      items: [
        {
          feature: 'Coverage Range',
          enabled: [
            '7nmi',
            '7nmi',
            '7nmi',
            '7nmi',
            '7nmi',
            '7nmi',
            '200 miles or CONUS'
          ]
        },
        {
          feature: 'Data Update Rate',
          enabled: [
            '10 seconds',
            '5 seconds',
            '1 second',
            '1 second',
            '1 second',
            '1 second',
            '1 second'
          ]
        }
      ]
    },
    {
      blockTitle: 'Aircraft Properties',
      items: [
        {
          feature: 'Call sign & aircraft type',
          enabled: [true, true, true, true, true, true, true]
        },
        {
          feature: 'Speed, Heading, Altitude',
          enabled: [false, true, true, true, true, true, true]
        },
        {
          feature: 'Origin and Destination',
          enabled: [false, false, true, true, true, true, true]
        },
        {
          feature: 'Mode S Address and Beacon Code',
          enabled: [false, false, true, true, true, true, true]
        }
      ]
    },
    {
      blockTitle: 'Aircraft Display',
      items: [
        {
          feature: 'Display Taxiing Aircraft',
          enabled: [true, true, true, true, true, true, true]
        },
        {
          feature: 'Distinguish Arrivals and Departures',
          enabled: [false, false, true, true, true, true, true]
        },
        {
          feature: 'Display Parked Aircraft',
          enabled: [false, false, false, false, false, false, 'Custom']
        },
        {
          feature: 'Display Airport Vehicles',
          enabled: [false, false, true, true, true, true, true]
        }
      ]
    },
    {
      blockTitle: 'Other Features',
      items: [
        {
          feature: 'Alternate Background Maps',
          enabled: [false, false, true, true, true, true, true]
        },
        {
          feature: 'Rotate the Map',
          enabled: [false, false, false, true, true, true, true]
        },
        {
          feature: 'Flight Table',
          enabled: [false, false, false, false, false, false, true]
        },
        {
          feature: 'Runway Timeline',
          enabled: [false, false, false, false, false, false, true]
        },
        {
          feature: 'Find/Follow Specific Flights',
          enabled: [false, false, false, false, false, false, true]
        },
        {
          feature: 'Color Schemes and Flight Filtering',
          enabled: [false, false, false, false, false, false, true]
        },
        {
          feature: 'Save/Reload Display Configurations',
          enabled: [false, false, false, false, false, false, true]
        },
        {
          feature: 'Improve Surveillance Coverage',
          enabled: [false, false, false, false, false, false, 'Custom']
        },
        {
          feature: 'Add New Airports',
          enabled: [false, false, false, false, false, false, 'Custom']
        },
        {
          feature: 'Playback',
          enabled: [false, false, false, false, false, false, 'Custom']
        },
        {
          feature: 'Metrics Reporting',
          enabled: [false, false, false, false, false, false, 'Custom']
        },
        {
          feature: 'Real-time Alerting',
          enabled: [false, false, false, false, false, false, 'Custom']
        },
        {
          feature: 'Customer Support',
          enabled: [false, false, false, false, false, false, 'Custom']
        }
      ]
    }
  ],
  basemapLayers: [
    {
      text: 'OpenStreetMap',
      type: 'osm',
      url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    },
    {
      text: 'Standard',
      type: 'xyz',
      url:
        'https://api.maptiler.com/maps/8832c698-c59a-4a41-8243-128c30e74cb9/{z}/{x}/{y}.png?key=ChghMRrAdbVhc6qft5PW'
    },
    {
      text: 'Light',
      type: 'xyz',
      url:
        'https://api.maptiler.com/maps/11f915a4-52b8-4de3-8736-ecb57da7e91f/{z}/{x}/{y}.png?key=ChghMRrAdbVhc6qft5PW'
    },
    {
      text: 'Dark',
      type: 'xyz',
      url:
        'https://api.maptiler.com/maps/54b10a88-2275-428a-b64d-8f60aa5949fb/{z}/{x}/{y}.png?key=ChghMRrAdbVhc6qft5PW'
    },
    {
      text: 'Satellite',
      type: 'xyz',
      url:
        'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=ChghMRrAdbVhc6qft5PW'
    }
  ],
  iconThemes: {
    standard: {
      arrival: '#0f05d5',
      departure: '#d80f05',
      noFp: '#406686',
      gse: '#ee8506',
      unknown: '#4b7ade'
    },
    neon: {
      arrival: '#34FD50',
      departure: '#FBB23F',
      noFp: '#FE1F4E',
      gse: '#FD2BFF',
      unknown: '#D1FE49'
    },
    gray: {
      arrival: '#8E8E93',
      departure: '#EDEDED',
      noFp: '#636366',
      gse: '#D1D1D6',
      unknown: '#000000'
    },
    high_contrast: {
      arrival: '#34cb10',
      departure: '#ff7c01',
      noFp: '#FF3B30',
      gse: '#ec2fe1',
      unknown: '#007AFF'
    }
  }
}
