import Vue from 'vue'
import { constants } from '../lib/Constants'

export default function({ store }) {
  const justSignedUp = window.localStorage.getItem(
    constants.storageKeys.justSignedUp
  )
  if (Vue.prototype.$auth.isAuthenticated === true && justSignedUp) {
    store.dispatch('user/saveTermsOfService').then(() => {
      window.localStorage.removeItem(constants.storageKeys.justSignedUp)
    })
  }
}
