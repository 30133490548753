import Vue from 'vue'

export default async function({ redirect }) {
  if (
    Vue.prototype.$auth.loading === false &&
    Vue.prototype.$auth.isAuthenticated === false
  ) {
    redirect('/login')
  } else {
    await Vue.prototype.$auth.getTokenSilently()
  }
}
