import Vue from 'vue';
import CookieControl from "\u002Fopt\u002Fmsv\u002Fmosaic-viewer-v2-frontend\u002Fnode_modules\u002Fnuxt-cookie-control\u002Fcomponents\u002FCookieControl.vue";
import CookieIframe from "\u002Fopt\u002Fmsv\u002Fmosaic-viewer-v2-frontend\u002Fnode_modules\u002Fnuxt-cookie-control\u002Fcomponents\u002FCookieIframe.vue";
export default(context, inject) =>{
  let cookies = {
    modal: false,
    consent: false,
    enabled: [],
    enabledList: [],
    optional: []
  }

  Object.assign(cookies, {"necessary":[{"name":"Necessary Cookies","identifier":"necessary","description":"Used for cookie control and subscription access, as well as Google Ads for non-paid account tiers.","initialState":true,"cookies":["cookie_control_consent","cookie_control_enabled_cookies","AV2_idToken","AV2_accessToken","AV2_tokensExpiry","AV2_rememberMe","com.auth0.auth","co%2Fverifier%2Fhttps%253A%252F%252Flogin-dev.airportviewer.com","__stripe_mid","__stripe_sid","recent-views","docs.prefs","cid"],"accepted":() => {
          // A bug in nuxt-cookie-control falsely fires this callback 1 time on initial page load prior to the user actually accepting
          // or declining. Don't consider necessary cookies actually accepted unless the cookie 'cookie_control_consent' exists with
          // value of 'true'.
          /*
          const cookieControlConsentValue = unescape(
            document.cookie.replace(
              new RegExp(
                '(?:^|.*;\\s*)' +
                  escape('cookie_control_consent').replace(/[-.+*]/g, '\\$&') +
                  '\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*'
              ),
              '$1'
            )
          )

          // if (cookieControlConsentValue === 'true')
          // console.log('Necessary cookies accepted') */
        },"declined":() => {
          // console.log('Necessary cookies declined')
        }}],"optional":[{"name":"Personalized Ads","description":"Google Personalized Ads","initialState":true,"src":"https:\u002F\u002Fpagead2.googlesyndication.com\u002Fpagead\u002Fjs\u002Fadsbygoogle.js","async":true,"cookies":["__gpi","__gads","ar_debug","SEARCH_SAMESITE"],"accepted":() => {
          /*
            https://support.google.com/adsense/answer/9042142?hl=en
            Set up the consent solution and act according to the user choice.
            If the user declines personalized ads,
            make sure to call (adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds=1;
            Remember that you need user consent for using cookies even for
            non-personalized ads in countries where the EU eprivacy directive requires it.

            Later, you can call (adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0 to resume sending ad requests.
            Without making this call, no ads will be shown.
          */

          if (window.adsbygoogle) {
            window.adsbygoogle.requestNonPersonalizedAds = 0
            window.adsbygoogle.pauseAdRequests = 0
          }
        },"declined":() => {
          // A bug in nuxt-cookie-control falsely fires this callback 1 time on initial page load prior to the user actually accepting
          // or declining. Don't consider optional cookies actually declined unless the cookie 'cookie_control_consent' exists with
          // value of 'true'.
          const cookieControlConsentValue = unescape(
            document.cookie.replace(
              new RegExp(
                '(?:^|.*;\\s*)' +
                  escape('cookie_control_consent').replace(/[-.+*]/g, '\\$&') +
                  '\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*'
              ),
              '$1'
            )
          )

          if (cookieControlConsentValue === 'true') {
            if (window.adsbygoogle) {
              window.adsbygoogle.requestNonPersonalizedAds = 1
              window.adsbygoogle.pauseAdRequests = 0
            }
          } else {
            // This happens on a fresh load when user has not consented or reload after user has selected "Delete All".
            // Delete any cookies hanging around from Stripe, Google Ads.  Leave Auth0 as those are controlled by logout function.
            const cookieNames = [
              '__stripe_mid',
              '__stripe_sid',
              'recent-views',
              'docs.prefs',
              'cid',
              '__gpi',
              '__gads',
              'ar_debug',
              'SEARCH_SAMESITE'
            ]

            for (const cookieName of cookieNames)
              document.cookie =
                encodeURIComponent(cookieName) +
                '=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
          }
        }}],"css":true,"cssPolyfill":true,"controlButton":true,"acceptNecessary":false,"barPosition":"bottom-full","iframe":"\u002Fopt\u002Fmsv\u002Fmosaic-viewer-v2-frontend\u002Fnode_modules\u002Fnuxt-cookie-control\u002Fcomponents\u002FCookieIframe.vue","component":"\u002Fopt\u002Fmsv\u002Fmosaic-viewer-v2-frontend\u002Fnode_modules\u002Fnuxt-cookie-control\u002Fcomponents\u002FCookieControl.vue","blockIframe":false,"colors":{"barTextColor":"#fff","modalOverlay":"#000","barBackground":"#db3539","barButtonColor":"#000","modalTextColor":"#525252","modalBackground":"#fff","modalOverlayOpacity":0.8,"modalButtonColor":"#fff","modalUnsavedColor":"#fff","barButtonHoverColor":"#fff","barButtonBackground":"#fff","modalButtonHoverColor":"#fff","modalButtonBackground":"#db3539","controlButtonIconColor":"#db3539","controlButtonBackground":"#fff","barButtonHoverBackground":"#333","checkboxActiveBackground":"#f3babc","checkboxInactiveBackground":"#808080","modalButtonHoverBackground":"#333","checkboxDisabledBackground":"#ddd","controlButtonIconHoverColor":"#fff","controlButtonHoverBackground":"#000","checkboxActiveCircleBackground":"#db3539","checkboxInactiveCircleBackground":"#dcdcdc","checkboxDisabledCircleBackground":"#fff"},"text":{"acceptAll":"Accept all","declineAll":"Delete all","manageCookies":"Manage cookies","unsaved":"","close":"Close","save":"Save","necessary":"Necessary cookies","optional":"Optional cookies","functional":"Functional cookies","blockedIframe":"To see this, please enable functional cookies","here":"here"},"globalName":"nuxt"});
  if({"barTextColor":"#fff","modalOverlay":"#000","barBackground":"#db3539","barButtonColor":"#000","modalTextColor":"#525252","modalBackground":"#fff","modalOverlayOpacity":0.8,"modalButtonColor":"#fff","modalUnsavedColor":"#fff","barButtonHoverColor":"#fff","barButtonBackground":"#fff","modalButtonHoverColor":"#fff","modalButtonBackground":"#db3539","controlButtonIconColor":"#db3539","controlButtonBackground":"#fff","barButtonHoverBackground":"#333","checkboxActiveBackground":"#f3babc","checkboxInactiveBackground":"#808080","modalButtonHoverBackground":"#333","checkboxDisabledBackground":"#ddd","controlButtonIconHoverColor":"#fff","controlButtonHoverBackground":"#000","checkboxActiveCircleBackground":"#db3539","checkboxInactiveCircleBackground":"#dcdcdc","checkboxDisabledCircleBackground":"#fff"} !== false){
    cookies.colors = {
      barTextColor: '#fff',
      modalOverlay: '#000',
      barBackground: '#000',
      barButtonColor: '#000',
      modalTextColor: '#000',
      modalBackground: '#fff',
      modalOverlayOpacity: 0.8,
      modalButtonColor: '#fff',
      modalUnsavedColor: '#fff',
      barButtonHoverColor: '#fff',
      barButtonBackground: '#fff',
      modalButtonHoverColor: '#fff',
      controlButtonIconColor: '#000',
      modalButtonBackground: '#000',
      controlButtonBackground: '#fff',
      barButtonHoverBackground: '#333',
      checkboxActiveBackground: '#000',
      controlButtonIconHoverColor: '#fff',
      checkboxInactiveBackground: '#000',
      modalButtonHoverBackground: '#333',
      checkboxDisabledBackground: '#ddd',
      controlButtonHoverBackground: '#000',
      checkboxActiveCircleBackground: '#fff',
      checkboxInactiveCircleBackground: '#fff',
      checkboxDisabledCircleBackground: '#fff',
    };
    Object.assign(cookies.colors, {"barTextColor":"#fff","modalOverlay":"#000","barBackground":"#db3539","barButtonColor":"#000","modalTextColor":"#525252","modalBackground":"#fff","modalOverlayOpacity":0.8,"modalButtonColor":"#fff","modalUnsavedColor":"#fff","barButtonHoverColor":"#fff","barButtonBackground":"#fff","modalButtonHoverColor":"#fff","modalButtonBackground":"#db3539","controlButtonIconColor":"#db3539","controlButtonBackground":"#fff","barButtonHoverBackground":"#333","checkboxActiveBackground":"#f3babc","checkboxInactiveBackground":"#808080","modalButtonHoverBackground":"#333","checkboxDisabledBackground":"#ddd","controlButtonIconHoverColor":"#fff","controlButtonHoverBackground":"#000","checkboxActiveCircleBackground":"#db3539","checkboxInactiveCircleBackground":"#dcdcdc","checkboxDisabledCircleBackground":"#fff"});
  }

  let methods = {
    get: (cookie) => {
      if(process.browser){
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        let name = `${cookie}=`;
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
      }
      return '';
    },

    set: ({name, value='', expires='', path='/', domain}) => {
      let domainName = domain ? domain : cookies.domain ? `.${cookies.domain}` : domain;
      if(process.browser){
        document.cookie = `${name}=${value};expires=${expires};path=${path}${domainName !== undefined ? `;domain=${domainName}` : ';'}`;
      } else if(process.server){
        context.res.setHeader('Set-Cookie', [`${name}=${value}; Expires=${expires}; Path=${path}${domainName !== undefined ? `; Domain=${domainName}` : ';'}`]);
      }
    },

    isEnabled: (identifier) => {
      return cookies.enabledList.includes(identifier) || cookies.enabledList.includes(cookies.slugify(identifier))
    },

    setBlockedIframes: (content) =>{
      let type = (typeof(content)).toLowerCase();
      let c = type !== 'string' ? JSON.stringify(content) : content;
      c = c.replace(/&lt;/g, '<');
      c = c.replace(/&gt;/g, '>');
      if(context.app.$cookies.enabled.filter(c =>{return c.name === 'functional'}).length === 0){
        c = c.replace(/<iframe/g, `<div class='cookieControl__BlockedIframe '`);
        c = c.replace(/<\/iframe/g, `<p>${context.app.$cookies.text.blockedIframe !== undefined ? context.app.$cookies.text.blockedIframe : ''} <a href='#' onclick='event.preventDefault(); $${cookies.globalName}.$cookies.modal = true'>${context.app.$cookies.text.here !== undefined ? context.app.$cookies.text.here: ''}</a></p></div`);
      }
      return type !== 'string' ? JSON.parse(c) : c
    },

    slugify: (str) =>{
      str = str.replace(/^\s+|\s+$/g, '');
      str = str.toLowerCase();
      let from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
      let to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
      for (let i = 0, l = from.length; i < l ; i++){
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

      return str;
    },

    remove: (name) =>{
      if(process.browser){
        let domain = window.location.hostname;
        cookies.set({name, expires: 'Thu, 01 Jan 1970 00:00:00 GMT', domain });
        for (let j = domain.split('.'); j.length;) {
          let o = j.join('.');
          cookies.set({name, expires: 'Thu, 01 Jan 1970 00:00:00 GMT', domain: `.${o}` });
          j.shift();
        }
      }
    },

    acceptNecessary: () => {
      let expires = new Date();
      expires.setFullYear(expires.getFullYear()+1);
      expires = expires.toUTCString();
      const value = cookies.necessary.map(c => c.identifier || cookies.slugify(getName(c.name)))
      cookies.set({name: 'cookie_control_enabled_cookies', value, expires});
      cookies.set({name: 'cookie_control_consent', value: true, expires});
      cookies.consent = true;
      if(process.client){
        setHead();
        callAcceptedFunctions();
      }
    },

    getName: (name) => {
      return typeof(name) === 'string' ? name : name[Object.keys(name)[0]]
    },

    setConsent: (isInit=false) =>{
      cookies.consent = cookies.get('cookie_control_consent') === 'true' ? true : false;
      cookies.enabled = [];
      cookies.enabledList = [];
      if(cookies.consent === true){
        let enabledFromCookie = cookies.get('cookie_control_enabled_cookies');
        cookies.enabled.push(...cookies.optional.filter(c => {
          let cookieName = typeof(c.name) === 'string' ? c.name : c.name[Object.keys(c.name)[0]]
          return enabledFromCookie.includes(c.identifier || cookies.slugify(cookieName))
        }));
        cookies.enabledList = cookies.enabled.length > 0 ? cookies.enabled.map(c => {
          let cookieName = typeof(c.name) === 'string' ? c.name : c.name[Object.keys(c.name)[0]]
          return c.identifier || cookies.slugify(cookieName)
        }) : [];
      }

      if(cookies.necessary) cookies.enabled.push(...cookies.necessary.filter(c => {return c.src || c.accepted}))

      if(process.client && !isInit){
        setHead();
        clearCookies();
        callAcceptedFunctions();
      }
    }
  }

  Object.assign(cookies, methods);

  const clearCookies = () =>{
    let disabled = cookies.optional.filter(c => {
      let cookieName = typeof(c.name) === 'string' ? c.name : c.name[Object.keys(c.name)[0]]
      return !cookies.enabledList.includes(c.identifier || cookies.slugify(cookieName))
    });
    if(disabled.length > 0){
      disabled.forEach(c => {
        if(c.declined) c.declined();
        if(c.cookies && c.cookies.length > 0){
          c.cookies.forEach(i => {
            cookies.remove(i);
          })
        }
        // if(c.src){
        //   for(let s of [...document.head.querySelectorAll(`script[src="${c.src}"]`)]){
        //     s.parentNode.removeChild(s)
        //   }
        // }
      })
    }
  }

  const setHead = () =>{
    if(cookies.enabled.length > 0){
      let head = document.getElementsByTagName('head')[0];
      cookies.enabled.forEach(c =>{
        if(c.src){
          let script = document.createElement('script');
          script.src = c.src;
          head.appendChild(script);
          script.addEventListener('load', () =>{
            if(c.accepted) c.accepted();
          })
        }
      })
    }
  }

  const callAcceptedFunctions = () =>{
    if(cookies.enabled.length > 0){
      cookies.enabled.forEach(c =>{
        if(c.accepted) c.accepted();
      })
    }
  }

  const capitalize = (s) =>{
    if(typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  cookies.setConsent(true)

  if(process.client){
    let globalName = capitalize(cookies.globalName) || 'Nuxt';
    window[`on${globalName}Ready`](() => {
      cookies.setConsent()
    })
  }

  inject('cookies', cookies);
  if(cookies.blockIframe) Vue.component('CookieIframe', CookieIframe);
  Vue.component('CookieControl', CookieControl);
}