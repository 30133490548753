// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./font/NunitoSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./font/NunitoSans-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:NunitoSan;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:NunitoSanBold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}.cookieControl__BarContainer{background-color:#db3539;position:absolute;bottom:0;right:0;left:0;font-family:NunitoSan,serif!important}.cookieControl__Bar{height:100%;background-color:rgba(0,0,0,.7)!important}.cookieControl__ModalContent ul ul{visibility:hidden;height:0;font-family:NunitoSan,serif!important}.cookieControl__BarButtons>button:first-of-type{display:none}.cookieControl__ModalContent input+label:before{content:\"OFF\";display:block;font-size:7px;font-weight:700;padding-left:1px;padding-top:2px;color:#000}.cookieControl__ModalContent input:checked+label:before{content:\"ON\";font-size:8px;padding-top:1px;color:#fff;font-weight:400}html{overflow-y:hidden;color:#525252;font-family:NunitoSan,serif}.theme--light.v-application{color:#525252!important}.v-application{color:#525252;font-family:NunitoSan,serif!important}h1,h2,h3,h4{font-family:NunitoSanBold,serif}h2{letter-spacing:0;opacity:1}.v-text-field--filled{background-color:#fff}.v-btn{text-transform:none!important}.v-text-field--outlined,.v-text-field--solo{border-radius:10px!important}.v-text-field__details>div.v-messages.error--text{text-align:right;color:#525252!important}.ol-zoom{top:.5em;left:unset!important;right:.5em!important}.settingsCardMobile{width:100%;bottom:0}.settingsCardDesktop{width:300px;top:.5em}.settingsButtonMobile{bottom:1.5em}.settingsButtonDesktop{top:.5em}.settingsCloseButtonMobile{left:45vw}.settingsCloseButtonDesktop{top:4em;left:275px}.dialog-maxwidth{max-width:540px}.dialog-title{font-size:40px;color:#525252}.text-primary{color:#db3539!important}", ""]);
// Exports
module.exports = exports;
