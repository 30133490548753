import Vue from 'vue'
import { constants } from '@/lib/Constants'

export default function({ store, route, redirect, from }) {
  // use this middleware to boostrap any data
  // that needs to be loaded into the Vuex Store

  // check for a redirect in the query params
  if (route.query.goto) {
    // sanitize the query parameter so that the user is not redirected to an external site maliciously.
    // the base parameter does not need to be environment specific.
    const url = new URL(route.query.goto, 'https://airportviewer.com')
    return redirect(url.pathname)
  }

  // check for PWA flag
  if (window.localStorage.getItem(constants.storageKeys.isPWA)) {
    store.commit('settings/setPWAFlag', true)
  }

  // init the auth user data if there is a user logged in
  if (Vue.prototype.$auth.user) {
    store.dispatch('user/initAuthUser', Vue.prototype.$auth.user).then(() => {
      if (
        Vue.prototype.$auth.isAuthenticated &&
        route.fullPath.includes('/pwa')
      ) {
        redirect('/airport/' + store.getters['user/defaultAirport'])
      }
    })
  }
}
