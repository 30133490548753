export const Utils = {
  isEmpty(x) {
    return x === null || x === undefined || x === ''
  },
  /**
   * Returns true if password is strong enough
   * @param {*} password
   */
  checkPasswordStrength(password) {
    if (Utils.isEmpty(password)) {
      return false
    }
    /*
     * this regex uses some negative look-aheads to check the following:
     * contains at least 1 digit
     * contains at least one lowercase character
     * contains at least one uppercase character
     * contains at least one symbol
     * contains at least 8 total characters
     */
    // NOTE These rules must be consistent with the configured rules on Auth0!
    return /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[(!@#$%^&*)]).{8,})/g.test(
      password
    )
  },
  /**
   * Returns true if email is in correct format.
   * Only checks for a @ and a . with some text in between.
   * @param {*} password
   */
  checkEmailFormat(email) {
    if (Utils.isEmpty(email)) {
      return false
    }
    return email.match(/.+@.+\..+/)
  },

  inputValidationIcon(v) {
    if (Utils.isEmpty(v)) {
      return ''
    }
    if (v.$dirty && v.$invalid) {
      return 'mdi-close'
    }
    if (v.$dirty && !v.$invalid) {
      return 'mdi-check'
    }
    return ''
  }
}
