import Vue from 'vue'
import { constants } from '../lib/Constants'
import { EventBus, Events } from '@/lib/Eventbus'

const FEATURES = {
  adsHidden: [
    constants.subscriptions.basic.code,
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayFlightDataOptions: [constants.subscriptions.enterprise.code],
  displayRegistration: [
    constants.subscriptions.basic.code,
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayHeading: [
    constants.subscriptions.basic.code,
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displaySpeed: [
    constants.subscriptions.basic.code,
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayAltitude: [
    constants.subscriptions.basic.code,
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayModeS: [
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayBeaconCode: [
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayOrgDest: [
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayUnknownVehicles: [
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayGroundVehicles: [
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayStateOutlines: [
    constants.subscriptions.free.code,
    constants.subscriptions.basic.code,
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayNexradWeather: [
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayMapTiler: [
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayOverlay: [
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayDistanceRings: [
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayAdaptations: [constants.subscriptions.enterprise.code],
  displayBackground: [
    constants.subscriptions.basic.code,
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  displayTheme: [
    constants.subscriptions.professional.code,
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ],
  customFontSize: [constants.subscriptions.enterprise.code],
  rotateMap: [
    constants.subscriptions.enterprise.code,
    constants.subscriptions.commercial_individual.code,
    constants.subscriptions.commercial_shared.code
  ]
}

export const getDefaultState = function() {
  return {
    email: '',
    subscriptions: [],
    userAppMetaData: {},
    userUserMetaData: {
      defaultAirport: constants.defaultAirport
    },
    isSocialLogin: false,
    access_token: ''
  }
}

export const state = () => {
  return getDefaultState()
}

export const getters = {
  getActiveSubscription(store) {
    let activeSubscription = {}

    store.subscriptions.forEach((s) => {
      if (s.status === 'active') {
        activeSubscription = s
      }
    })

    return activeSubscription
  },
  getPendingSubscription(store) {
    let pendingSubscription = {}

    store.subscriptions.forEach((s) => {
      if (s.status === 'trialing') {
        pendingSubscription = s
      }
    })

    return pendingSubscription
  },
  appMetadata: (store) => {
    return store.userAppMetaData
  },
  userMetadata: (store) => {
    return store.userUserMetaData
  },
  defaultAirport: (store) => {
    return store.userUserMetaData.defaultAirport || constants.defaultAirport
  },
  termsOfService: (store) => {
    return store.userUserMetaData.termsOfService
  },
  privacyPolicy: (store) => {
    return store.userUserMetaData.privacyPolicy
  },
  cookieConsent: (store) => {
    return store.userUserMetaData.cookieConsent
  },
  featureAvailable: (store) => (feature) => {
    if (typeof FEATURES[feature] === 'undefined') {
      return false
    }
    let activeSubscription = {}

    store.subscriptions.forEach((s) => {
      if (s.status === 'active') {
        activeSubscription = s
      }
    })

    const currentSubscription = activeSubscription.id
      ? activeSubscription.plan.nickname.toLowerCase().replaceAll(' ', '_')
      : 'free'

    // Mike Hill
    // Is this best way to do this?  Treat all commercial_custom_* subscription variants
    // as getting all included features from commercial_shared.
    if (currentSubscription.indexOf('commercial_custom_') === 0) {
      return FEATURES[feature].includes(
        constants.subscriptions.commercial_shared.code
      )
    } else {
      return FEATURES[feature].includes(currentSubscription)
    }
  }
}

export const mutations = {
  setSubscriptions(store, subscriptions) {
    Vue.set(store, 'subscriptions', subscriptions)
  },
  setMetaData(store, metaData) {
    Vue.set(store, 'userAppMetaData', metaData)
  },
  setUserMetaData(store, metaData) {
    Vue.set(store, 'userUserMetaData', metaData)
  },
  setUserEmail(store, email) {
    Vue.set(store, 'email', email)
  },
  setSocialLogin(store, flag) {
    Vue.set(store, 'isSocialLogin', flag)
  },
  setDefaultAirport(store, airport) {
    Vue.set(store, 'defaultAirport', airport)
    Vue.set(store.userUserMetaData, 'defaultAirport', airport)
  },
  setTermsOfService(store, terms) {
    Vue.set(store.userUserMetaData, 'termsOfService', terms)
  },
  setPrivacyPolicy(store, privacyPolicy) {
    Vue.set(store.userUserMetaData, 'privacyPolicy', privacyPolicy)
  },
  setCookieConsent(store, cookieConsent) {
    Vue.set(store.userUserMetaData, 'cookieConsent', cookieConsent)
  },
  setAccessToken(store, token) {
    Vue.set(store, 'access_token', token)
  },
  reset(store) {
    Object.assign(store, getDefaultState())
  }
}

export const actions = {
  async getActiveSubscription({ commit, state }, force) {
    // only query for subscriptions if the subscriptions array is empty
    if (state.subscriptions.length === 0 || force === true) {
      const token = await Vue.prototype.$auth.getTokenSilently()

      if (typeof token === 'undefined') {
        return
      }

      commit('setAccessToken', token)
      const r = await this.$axios.get(
        process.env.API_URL + '/api/user/subscription',
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      if (r.data.tellClientToReloadToken === true) {
        await Vue.prototype.$auth.refreshTokens()
      }

      commit('setSubscriptions', r.data.subscriptions)
    }
  },
  logout({ commit }) {
    commit('reset')
  },
  initAuthUser({ commit, dispatch }, user) {
    dispatch('findAndSetAppMetaData', user)
    dispatch('findAndSetUserMetaData', user)
    dispatch('determineAuthServer', user.sub)
    commit('setUserEmail', user.email)

    EventBus.$emit(Events.data.userLoaded)
  },
  findAndSetAppMetaData({ commit }, user) {
    const metadata = user[process.env.METADATA_NAMESPACE + 'app_metadata']
    if (metadata !== null && metadata !== undefined) {
      commit('setMetaData', metadata)
    }
  },
  findAndSetUserMetaData({ commit, getters, dispatch }, user) {
    const metadata = user[process.env.METADATA_NAMESPACE + 'user_metadata']
    if (metadata !== null && metadata !== undefined) {
      // if the user does not have a default airport set, then set the default.
      if (!metadata.defaultAirport) {
        metadata.defaultAirport = getters.defaultAirport
        dispatch('saveDefaultAirport', metadata.defaultAirport)
      }

      commit('setUserMetaData', metadata)
    }
  },
  determineAuthServer({ commit }, sub) {
    // 'sub' claim includes authorization server id and the user's unique id, separated by a |
    // auth0 = auth0|<id>
    // google = google-oauth2|<id>
    if (sub !== null && sub !== undefined) {
      const parts = sub.split('|')
      if (parts.length > 1) {
        this.authServer = parts[0]
      }
    }
    commit('setSocialLogin', this.authServer !== 'auth0')
  },
  setAccessToken({ commit }, token) {
    commit('setAccessToken', token)
  },
  async getTermsOfService({ getters, commit }) {
    if (getters.termsOfService) {
      return getters.termsOfService
    }

    const token = await Vue.prototype.$auth.getTokenSilently()
    const r = await this.$axios.get(
      process.env.API_URL + '/api/user/terms-of-service',
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )

    commit('setTermsOfService', r.data.termsOfService)

    return r.data.termsOfService
  },
  async getPrivacyPolicy({ getters, commit }) {
    if (getters.privacyPolicy) {
      return getters.privacyPolicy
    }

    const token = await Vue.prototype.$auth.getTokenSilently()
    const r = await this.$axios.get(
      process.env.API_URL + '/api/user/privacy-policy',
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )

    commit('setPrivacyPolicy', r.data.privacyPolicy)

    return r.data.privacyPolicy
  },
  async getCookieConsent({ getters, commit }) {
    if (getters.cookieConsent) {
      return getters.cookieConsent
    }

    const token = await Vue.prototype.$auth.getTokenSilently()
    const r = await this.$axios.get(
      process.env.API_URL + '/api/user/cookie-consent',
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )

    commit('setCookieConsent', r.data.cookieConsent)

    return r.data.cookieConsent
  },
  async updateUserEmail({ commit }, email) {
    const token = await Vue.prototype.$auth.getTokenSilently()
    return this.$axios
      .post(
        process.env.API_URL + '/api/user/email',
        {
          email
        },
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      .then(() => {
        commit('setUserEmail', email)
      })
  },
  async saveTermsOfService({ commit }) {
    const token = await Vue.prototype.$auth.getTokenSilently()
    const r = await this.$axios.put(
      process.env.API_URL + '/api/user/terms-of-service',
      {
        version: constants.termsOfServiceVersion
      },
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )

    commit('setTermsOfService', r.data.termsOfService)

    return r.data.termsOfService
  },
  async savePrivacyPolicy({ commit }) {
    const token = await Vue.prototype.$auth.getTokenSilently()
    const r = await this.$axios.put(
      process.env.API_URL + '/api/user/privacy-policy',
      {
        version: constants.privacyPolicyVersion
      },
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )

    commit('setPrivacyPolicy', r.data.privacyPolicy)

    return r.data.privacyPolicy
  },
  async saveCookieConsent(context, cookieConsent) {
    const token = await Vue.prototype.$auth.getTokenSilently()
    const r = await this.$axios.put(
      process.env.API_URL + '/api/user/cookie-consent',
      cookieConsent,
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )

    return r.data.cookieConsent
  },
  async saveDefaultAirport(context, airport) {
    const token = await Vue.prototype.$auth.getTokenSilently()
    return this.$axios.put(
      process.env.API_URL + '/api/user/default-airport',
      {
        defaultAirport: airport
      },
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )
  }
}
