import Vue from 'vue'

const checkAuthPluginReady = function() {
  return new Promise((resolve) => {
    if (Vue.prototype.$auth.loading === true) {
      setTimeout(async () => {
        await checkAuthPluginReady()
        resolve()
      }, 10)
    } else {
      resolve()
    }
  })
}

/**
 * Use this middleware to block the application loading until app critical plugins are ready
 *
 * @param context
 * @returns {Promise<void>}
 */
export default async function(context) {
  // checks and waits for the Auth0WebPlugin to be ready
  await checkAuthPluginReady()
}
