<style scoped>
.auth0-lock.auth0-lock .auth0-lock-header {
  position: inherit;
}
</style>
<template>
  <v-app light>
    <HeaderLoggedIn v-if="$auth.isAuthenticated" />
    <HeaderLoggedOut v-else />
    <v-main>
      <nuxt />
      <CookieControl>
        <template v-slot:bar>
          <h3>Cookie Consent</h3>
          <p>
            Cookies are important to the proper functioning of the Airport
            Viewer website and service. We use cookies and similar methods to
            recognize visitors and remember their preferences, to improve your
            experience (e.g., to remember log-in details), to collect statistics
            about how the site is used, and, in certain cases, to deliver
            content tailored to your interests. Our Cookie Policy is part of our
            Privacy Policy; see our
            <a href="/privacy" style="color: white;">Privacy Policy</a> for
            information. By choosing to Accept Cookies you consent to the these
            these methods by us and third parties. The Manage Cookies
            Preferences button allows you to chose which types of cookies you
            accept. You may withdraw or modify your consent via the Manage
            Cookie Preferences button at any time.
          </p>
        </template>
      </CookieControl>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import HeaderLoggedOut from '../components/HeaderLoggedOut'
import HeaderLoggedIn from '../components/HeaderLoggedIn'
import Footer from '../components/Footer'

export default {
  middleware: ['isReady', 'boostrap', 'googleAnalytics'],
  components: { HeaderLoggedIn, HeaderLoggedOut, Footer },
  data() {
    return {
      drawer: false,
      title: 'Viewer',
      cookies: this.$cookies
    }
  },
  computed: {
    cookieConsent() {
      let cookieConsent

      if (this.$auth.isAuthenticated) {
        cookieConsent = {
          consent: this.$cookies.consent,
          enabledList: this.$cookies.enabledList.join()
        }
      }

      return cookieConsent
    }
  },
  watch: {
    async cookieConsent() {
      // If cookieConsent is different than what is currently in state, save update.
      if (this.$auth.isAuthenticated) {
        const storedConsent = await this.$store.dispatch(
          'user/getCookieConsent'
        )

        if (
          !storedConsent ||
          this.cookieConsent.consent !== storedConsent.consent ||
          this.cookieConsent.enabledList !== storedConsent.enabledList
        ) {
          this.$store
            .dispatch('user/saveCookieConsent', this.cookieConsent)
            .then((r) => {
              this.setCookieConsent(r)
            })
        }
      }
    }
  },
  created() {
    if (this.$auth.user) {
      this.initAuthUser(this.$auth.user)
    }
  },
  methods: {
    ...mapActions({
      initAuthUser: 'user/initAuthUser'
    }),
    ...mapMutations({
      setCookieConsent: 'user/setCookieConsent'
    }),
    login() {
      this.$auth.loginWithRedirect()
    },
    logout() {
      this.$auth.logout()
    }
  }
}
</script>
