import Vue from 'vue'
import { constants } from '../lib/Constants'

export default async function({ store, redirect }) {
  if (Vue.prototype.$auth.isAuthenticated) {
    const tos = await store.dispatch('user/getTermsOfService')
    if (!tos || tos.length < 1) {
      redirect('/terms')
    }

    let maxVersionAccepted = null

    for (const acceptedTos of tos) {
      const tosVer = +acceptedTos.version
      if (maxVersionAccepted == null || tosVer > maxVersionAccepted)
        maxVersionAccepted = tosVer
    }

    const currentTosVersion = +constants.termsOfServiceVersion

    if (maxVersionAccepted < currentTosVersion) {
      redirect('/terms')
    }
  }
}
