import Vue from 'vue'
import { EventBus, Events } from '../lib/Eventbus'
import { constants } from '../lib/Constants'

const defaultSettings = {
  iconLabel: 'acid',
  arrivals: true,
  departures: true,
  noFp: true,
  gse: true,
  unknown: false,
  states: false,
  nexrad: false,
  basemapLayer: null,
  adaptationBasemap: false,
  adaptationGates: false,
  adaptationGateFilters: '',
  adaptationSpots: false,
  adaptationSpotFilters: '',
  distanceRings: false,
  distanceRingSize: 2,
  distanceRingMaxDistance: 10,
  fontSize: 16,
  theme: 'standard'
}

export const state = () => {
  return {
    isPWA: false,
    isForceDisconnected: false,
    settings: {
      airport: {
        selectedIata: null,
        current: null,
        previous: null
      },
      layers: defaultSettings,
      center: [-100.371094, 39.909736],
      zoom: 5
    }
  }
}

export const mutations = {
  setPWAFlag(state, flag) {
    state.isPWA = flag
  },
  setForceDisconnectedFlag(state, flag) {
    state.isForceDisconnected = flag
  },
  setFontSize(state, v) {
    Vue.set(state.settings.layers, 'fontSize', v)
  },
  setLayerFlag(state, { layer, flag }) {
    Vue.set(state.settings.layers, layer, flag)
  },
  setZoom(state, zoom) {
    Vue.set(state.settings, 'zoom', zoom)
  },
  setCenter(state, center) {
    Vue.set(state.settings, 'center', center)
  },
  setState(state, prevState) {
    Vue.set(state, 'settings', prevState)
    EventBus.$emit(Events.map.settings.loaded)
  },
  setLayers(state, prevState) {
    Vue.set(state.settings, 'layers', prevState)
    EventBus.$emit(Events.map.settings.loaded)
  },
  setCurrentAirport(state, airport) {
    state.settings.airport.current = airport
  },
  setPreviousAirport(state, airport) {
    // save it to localstorage
    window.localStorage.setItem(constants.storageKeys.previousAirport, airport)
    state.settings.airport.previous = airport
  },
  setSelectedIata(state, iata) {
    state.settings.airport.selectedIata = iata
  }
}

export const getters = {
  isPWA: (state) => {
    return state.isPWA
  },
  isForceDisconnected: (state) => {
    return state.isForceDisconnected
  },
  basemapLayer: (state, getters, rootState, rootGetters) => {
    let layer = state.settings.layers.basemapLayer
    const sub = rootGetters['user/getActiveSubscription']

    if (
      layer == null ||
      sub == null ||
      sub.plan == null ||
      sub.plan.nickname == null ||
      sub.plan.nickname.toLowerCase() === constants.subscriptions.free.code
    ) {
      // Extra check to enforce guest and free users can
      // only get the OSM basemap
      layer = constants.basemapLayers[0]
    }

    return layer
  },
  currentAirport: (state) => {
    return state.settings.current
  },
  previousAirport: (state, getters, rootState, rootGetters) => {
    // get the previous airport
    // order of priority
    // 1. settings/airport/previous
    // 2. window.localstorage
    // 3. user default airport
    // 4. default to ATL
    let previousAirport = state.settings.airport.previous

    if (!previousAirport) {
      previousAirport = window.localStorage.getItem(
        constants.storageKeys.previousAirport
      )
    }

    if (!previousAirport) {
      previousAirport = rootGetters['user/defaultAirport']
    }

    if (!previousAirport) {
      previousAirport = constants.defaultAirport
    }

    return previousAirport
  },
  iataCode(state) {
    return state.settings.airport.current
      ? state.settings.airport.current.iata_code
      : null
  },
  selectedIata(state) {
    return state.settings.airport.selectedIata
  }
}

export const actions = {
  reset({ commit, dispatch }) {
    commit('setLayers', defaultSettings)
    dispatch('exportSettings')
  },
  exportSettings(store) {
    this.$axios.put(
      process.env.API_URL + '/api/user/settings',
      store.rootState.settings.settings.layers,
      {
        headers: {
          Authorization: 'Bearer ' + store.rootState.user.access_token
        }
      }
    )
  },
  importSettings({ commit, rootState }, overrideCurrentAirport) {
    this.$axios
      .get(process.env.API_URL + '/api/user/settings', {
        headers: {
          Authorization: 'Bearer ' + rootState.user.access_token
        }
      })
      .then((r) => {
        commit('setLayers', r.data)
      })
  }
}
