<style>
.v-overlay--active {
  backdrop-filter: blur(3px);
}
</style>
<template>
  <v-dialog
    v-if="showButton"
    v-model="signupDialog"
    content-class="dialog-maxwidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed outlined color="white" v-bind="attrs" v-on="on">
        SIGN UP
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dense flat tile>
        <v-spacer />
        <v-btn small icon @click="signupDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <Signup @login="toggleLogin" />
    </v-card>
  </v-dialog>
</template>
<script>
import { EventBus, Events } from '../../lib/Eventbus'
import Signup from './Signup'
export default {
  name: 'SignupModal',
  components: { Signup },
  data() {
    return {
      signupDialog: false
    }
  },
  computed: {
    showButton() {
      const pages = ['login', 'signup', 'forgot']
      return !pages.includes(this.$route.name)
    }
  },
  created() {
    EventBus.$on(Events.navigation.openSignupDialog, () => {
      this.signupDialog = true
    })
  },
  beforeDestroy() {
    EventBus.$off(Events.navigation.openSignupDialog)
  },
  methods: {
    toggleLogin(data) {
      EventBus.$emit(Events.navigation.openLoginDialog)
      this.signupDialog = false
    }
  }
}
</script>
