import Vue from 'vue'

export default async function({ store, redirect, route }) {
  if (Vue.prototype.$auth.isAuthenticated) {
    await store.dispatch('user/getActiveSubscription')
    if (
      typeof store.getters['user/getActiveSubscription'].id === 'undefined' &&
      route.path !== '/subscription'
    ) {
      redirect('/subscription')
    }
  }
}
