/* global dataLayer */
/* global ga */
window.dataLayer = window.dataLayer || []

function gtag() {
  dataLayer.push(arguments)
}

export default function({ route }) {
  if (
    process.env.ENABLE_GOOGLE_ANALYTICS === '1' &&
    typeof ga !== 'undefined'
  ) {
    gtag('js', new Date())
    gtag('config', process.env.GOOGLE_ANALYTICS_ID, { page_path: route.path })
    ga('send', 'pageview')
  }
}
