<style scoped>
.divider {
  border-color: rgba(0, 0, 0, 0.5) !important;
}
.or {
  border-color: rgba(0, 0, 0, 0.5) !important;
  border-width: 1px;
  border-style: solid;
}
</style>
<template>
  <div class="d-flex justify-center align-center">
    <v-divider class="divider" />
    <v-avatar size="40" class="or">
      OR
    </v-avatar>
    <v-divider class="divider" />
  </div>
</template>
<script>
export default {
  name: 'OrDivider'
}
</script>
